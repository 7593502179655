/*
| Developed by Dirupt
| Filename : MobileNavBackground.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNav';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MobileNavBackgroundProps {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const DEBUG = false;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MobileNavBackground: React.FC<MobileNavBackgroundProps> = () => {
  const size = useWindowSize();
  const theme = useTheme();

  // Coordinates
  // ----------------------------------------------------------------------------
  const topLeft = [0, 0];
  const bottomLeft = [0, MOBILE_NAV_HEIGHT];
  const bottomRight = [size.width, MOBILE_NAV_HEIGHT];
  const topRight = [size.width, 0];
  const centerTopRight = [size.width / 2 + 69, 0];
  const centerTopRightWristPoint = [size.width / 2 + 69 * 0.65, 0];
  const centerTopRightCurve = [size.width / 2 + 69 / 2, MOBILE_NAV_HEIGHT * 0.35];
  const centerCurveWristPoint = [size.width / 2 + 69 * 0.35, MOBILE_NAV_HEIGHT * 0.72];
  const centerCurve = [size.width / 2, MOBILE_NAV_HEIGHT * 0.72];
  const centerTopLeftCurveWristPoint = [size.width / 2 - 69 * 0.35, MOBILE_NAV_HEIGHT * 0.72];
  const centerTopLeftCurve = [size.width / 2 - 69 / 2, MOBILE_NAV_HEIGHT * 0.35];
  const centerTopLeftWristPoint = [size.width / 2 - 69 * 0.65, 0];
  const centerTopLeft = [size.width / 2 - 69, 0];

  // Render
  // ----------------------------------------------------------------------------
  return <svg width={size.width} viewBox={`0 0 ${size.width} ${MOBILE_NAV_HEIGHT}`} fill="none" data-sentry-element="svg" data-sentry-component="MobileNavBackground" data-sentry-source-file="MobileNavBackground.tsx">
			<path fill={theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.silver} fillOpacity="1" d={`
					M${topLeft.join(',')}
					L${bottomLeft.join(',')}
					L${bottomRight.join(',')}
					L${topRight.join(',')}
					L${centerTopRight.join(',')}
					C${centerTopRight.join(',')} ${centerTopRightWristPoint.join(',')} ${centerTopRightCurve.join(',')}
					C${centerTopRightCurve.join(',')} ${centerCurveWristPoint.join(',')} ${centerCurve.join(',')}
					C${centerCurve.join(',')} ${centerTopLeftCurveWristPoint.join(',')} ${centerTopLeftCurve.join(',')}
					C${centerTopLeftCurve.join(',')} ${centerTopLeftWristPoint.join(',')} ${centerTopLeft.join(',')}
					Z
				`} data-sentry-element="path" data-sentry-source-file="MobileNavBackground.tsx" />

			{DEBUG ? <React.Fragment>
					{/* Top left */}
					<circle opacity={0.5} fill="red" cx={topLeft[0]} cy={topLeft[1]} r="5" />
					{/* Bottom left */}
					<circle opacity={0.5} fill="green" cx={bottomLeft[0]} cy={bottomLeft[1]} r="5" />
					{/* Bottom right */}
					<circle opacity={0.5} fill="green" cx={bottomRight[0]} cy={bottomRight[1]} r="5" />
					{/* Top right */}
					<circle opacity={0.5} fill="red" cx={topRight[0]} cy={topRight[1]} r="5" />
					{/* Center top right */}
					<circle opacity={0.5} fill="orange" cx={centerTopRight[0]} cy={centerTopRight[1]} r="5" />

					{/* Center top right curve */}
					<circle opacity={0.5} fill="cyan" cx={centerTopRightCurve[0]} cy={centerTopRightCurve[1]} r="5" />
					{/* Center top right wrist */}
					<line stroke="red" strokeWidth={2} x1={centerTopRight[0]} y1={centerTopRight[1]} x2={centerTopRightWristPoint[0]} y2={centerTopRightWristPoint[1]} />
					<circle opacity={0.5} fill="white" cx={centerTopRightWristPoint[0]} cy={centerTopRightWristPoint[1]} r="5" />

					{/*  Center curve */}
					<circle opacity={0.5} fill="orange" cx={centerCurve[0]} cy={centerCurve[1]} r="5" />
					{/* Center wrist */}
					<line stroke="red" strokeWidth={2} x1={centerCurve[0]} y1={centerCurve[1]} x2={centerCurveWristPoint[0]} y2={centerCurveWristPoint[1]} />
					<circle opacity={0.5} fill="white" cx={centerCurveWristPoint[0]} cy={centerCurveWristPoint[1]} r="5" />

					{/* Center top left curve */}
					<circle opacity={0.5} fill="cyan" cx={centerTopLeftCurve[0]} cy={centerTopLeftCurve[1]} r="5" />
					{/* Center top left curve wrist */}
					<line stroke="red" strokeWidth={2} x1={centerTopLeftCurve[0]} y1={centerTopLeftCurve[1]} x2={centerTopLeftCurveWristPoint[0]} y2={centerTopLeftCurveWristPoint[1]} />
					<circle opacity={0.5} fill="white" cx={centerTopLeftCurveWristPoint[0]} cy={centerTopLeftCurveWristPoint[1]} r="5" />

					{/* Center top left */}
					<circle opacity={0.5} fill="orange" cx={centerTopLeft[0]} cy={centerTopLeft[1]} r="5" />
					{/*	Center top left wrist */}
					<line stroke="red" strokeWidth={2} x1={centerTopLeft[0]} y1={centerTopLeft[1]} x2={centerTopLeftWristPoint[0]} y2={centerTopLeftWristPoint[1]} />
					<circle opacity={0.5} fill="white" cx={centerTopLeftWristPoint[0]} cy={centerTopLeftWristPoint[1]} r="5" />
				</React.Fragment> : null}
		</svg>;
};