/*
| Developed by Dirupt
| Filename : UsernameWithBadge.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, Skeleton, Tooltip, Typography, TypographyProps } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { User } from '@/services/api/modules/user/user.contract';
import { BadgeAdmin, BadgeAmbassador, BadgePartner, BadgePremium } from '@/components/common/svgs/badge';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UsernameWithBadgeProps {
  user?: User;
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UsernameWithBadge: React.FC<UsernameWithBadgeProps> = ({
  user,
  boxProps,
  typographyProps
}) => {
  const {
    t
  } = useTranslation();

  // Do not show badge if user is not admin, ambassador, partner or if user is not subscription active and is user
  // ---------------------------------------------------------------------------
  const showBadge = React.useMemo(() => {
    if (!user) return false;
    return user.isAdmin || user.isAmbassador || user.isPartner || user.isRedeemed && user.isUser;
  }, [user]);

  // Render
  // ---------------------------------------------------------------------------
  if (!user) {
    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} {...boxProps}>
				<Skeleton animation={'wave'} width={50} height={25} />
				<Skeleton animation={'pulse'} width={25} height={25} />
			</Box>;
  }
  return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={0.5} {...boxProps} data-sentry-element="Box" data-sentry-component="UsernameWithBadge" data-sentry-source-file="UsernameWithBadge.tsx">
			<Typography {...typographyProps} data-sentry-element="Typography" data-sentry-source-file="UsernameWithBadge.tsx">
				{user?.userInformation?.pseudo ? user.userInformation.pseudo : <Skeleton animation={'wave'} width={50} height={25} />}
			</Typography>
			{showBadge ? <Tooltip title={t(`common:userRoles.${user.role}`)}>
					<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
						{user.isAdmin ? <BadgeAdmin sx={{
          fontSize: 'inherit'
        }} /> : null}
						{user.isAmbassador ? <BadgeAmbassador sx={{
          fontSize: 'inherit'
        }} /> : null}
						{user.isPartner ? <BadgePartner sx={{
          fontSize: 'inherit'
        }} /> : null}
						{user.isRedeemed && user.isUser ? <BadgePremium sx={{
          fontSize: 'inherit'
        }} /> : null}
					</Box>
				</Tooltip> : null}
		</Box>;
};