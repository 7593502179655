/*
| Developed by Dirupt
| Filename : BadgePartner.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface BadgePartnerProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const BadgePartner: React.FC<BadgePartnerProps> = React.forwardRef((props, ref) => {
  return <SvgIcon {...props} inheritViewBox ref={ref}>
			<svg viewBox="0 0 64 64">
				<path d="M59.3466 25.6977C58.3841 25.1326 57.5099 24.4173 56.777 23.5785C56.8477 22.4041 57.1302 21.2562 57.5982 20.1878C58.4636 17.7418 59.4437 14.978 57.872 12.8235C56.3002 10.669 53.3333 10.7396 50.7285 10.8014C49.5806 10.9162 48.415 10.8456 47.2936 10.5719C46.6932 9.60055 46.2693 8.53212 46.0309 7.41071C45.2892 4.89415 44.4503 2.05088 41.8631 1.2032C39.3731 0.399671 37.0596 2.16568 35.0199 3.71976C34.1369 4.52329 33.1214 5.15905 32.0088 5.60055C30.8874 5.16788 29.8543 4.53212 28.9625 3.71976C26.9316 2.17451 24.6269 0.390841 22.1192 1.2032C19.5408 2.04205 18.702 4.89415 17.9514 7.41071C17.713 8.52329 17.2892 9.59172 16.6976 10.563C15.5673 10.8368 14.4106 10.9162 13.2539 10.8014C10.6402 10.7308 7.69978 10.6513 6.11038 12.8235C4.52097 14.9957 5.51876 17.7418 6.38411 20.1789C6.86093 21.2562 7.14349 22.4041 7.22296 23.5697C6.49007 24.4085 5.62472 25.1326 4.65342 25.6889C2.47241 27.1723 0 28.8677 0 31.6226C0 34.3776 2.47241 36.0553 4.65342 37.5564C5.61589 38.1215 6.49007 38.8368 7.22296 39.6756C7.15232 40.85 6.87859 41.9979 6.4106 43.0752C5.54525 45.5123 4.57395 48.2849 6.13687 50.4306C7.69978 52.5763 10.6578 52.5145 13.2804 52.4527C14.4283 52.3379 15.5938 52.4085 16.7241 52.6822C17.3245 53.6535 17.7483 54.7308 17.9868 55.8434C18.7285 58.3599 19.5673 61.2032 22.1545 62.0509C22.5695 62.1833 23.0022 62.254 23.4349 62.254C25.5276 61.9538 27.4702 61.0001 28.989 59.5343C29.872 58.7308 30.8874 58.095 32 57.6535C33.1214 58.0862 34.1545 58.722 35.0464 59.5343C37.0861 61.0884 39.3907 62.8633 41.8896 62.0509C44.468 61.212 45.3068 58.3599 46.0574 55.8522C46.2958 54.7308 46.7196 53.6624 47.3201 52.6911C48.4415 52.4173 49.5982 52.3379 50.7461 52.4527C53.3598 52.5145 56.3002 52.6028 57.8896 50.4306C59.479 48.2584 58.4812 45.5123 57.6159 43.0663C57.1391 41.9979 56.8565 40.85 56.777 39.6756C57.5099 38.8368 58.3841 38.1127 59.3466 37.5564C61.5276 36.073 64 34.3688 64 31.6226C64 28.8765 61.5188 27.1811 59.3377 25.6889L59.3466 25.6977Z" fill="#FF416D" />
				<path fillRule="evenodd" clipRule="evenodd" d="M18.7903 41.1237C18.0751 40.7352 17.8013 39.8434 18.181 39.1193C18.181 39.1193 19.6203 36.4262 19.6203 36.4173C20 35.7109 20.9183 35.4372 21.6247 35.808C22.3311 36.1877 22.6049 37.1061 22.234 37.8125L20.7859 40.5145C20.3974 41.2297 19.5055 41.5034 18.7903 41.1149V41.1237Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M47.9912 33.9891C47.7528 33.6977 47.4614 33.4416 47.117 33.2473L39.2583 28.7705C38.5695 28.382 37.8631 28.223 37.1391 28.223C35.7439 28.223 34.3135 28.8058 32.9095 29.3709C30.2428 30.4482 27.9205 31.393 25.7395 29.0177C27.8499 27.7374 29.6512 26.3511 31.1082 24.8765C31.7527 24.2319 32.3973 23.6756 33.0419 23.2341C36.1148 21.1149 38.234 21.9802 40.2914 22.8191C41.7925 23.4283 43.3377 24.0641 44.9536 23.2164L48.1678 21.4946L52.3002 32.1524L47.9912 33.9891Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M46.9227 36.6381C47.3112 35.9317 47.0463 35.031 46.3311 34.6248L38.4724 30.148C37.1214 29.3798 35.4437 30.0597 33.5099 30.8456C30.596 32.02 26.9757 33.4946 23.9117 29.2738C23.7792 29.0884 23.7351 28.8588 23.7792 28.6381C23.8234 28.4173 23.9647 28.2319 24.1589 28.1171C26.4901 26.7838 28.4503 25.318 29.9867 23.7639C30.3841 23.3577 30.7903 22.9957 31.1965 22.6513C29.4569 21.8566 27.9823 22.2098 26.4282 22.5718C24.9271 22.925 23.3819 23.2871 21.8013 22.4129L17.3245 20.3467L12.5563 30.5012C12.6357 30.5365 12.724 30.5718 12.8212 30.616C14.1545 31.2164 16.6446 32.3379 17.1037 32.5145C18.2693 32.9648 18.5519 34.1039 18.7196 35.0045C19.0817 34.6248 19.532 34.3423 20.053 34.1833C20.8388 33.9449 21.6689 34.0244 22.3841 34.4129C23.0993 34.7926 23.6291 35.4372 23.8675 36.2231C23.9293 36.4173 23.9647 36.6204 23.9912 36.8147C24.6799 36.6646 25.4304 36.744 26.1015 37.1061C26.8168 37.4858 27.3466 38.1303 27.585 38.9162C27.6026 38.9692 27.6115 39.0222 27.6291 39.0663C27.6821 39.0487 27.7263 39.031 27.7792 39.0133C28.5651 38.7749 29.3951 38.8544 30.1104 39.2429C30.8256 39.6226 31.3554 40.2672 31.5938 41.0531C31.6115 41.1061 31.6203 41.159 31.638 41.2032C31.6821 41.1855 31.7351 41.1679 31.7881 41.1502C32.5739 40.9118 33.404 40.9913 34.1192 41.3798C34.4724 41.5652 34.7726 41.8213 35.0287 42.1127L38.4724 44.02C39.1876 44.4173 40.0883 44.1524 40.4856 43.4372C40.8742 42.7308 40.6093 41.8213 39.894 41.4239L35.0463 38.6955C34.6666 38.4835 34.5254 37.9979 34.7461 37.6182C34.958 37.2385 35.4437 37.0972 35.8234 37.318L41.9603 40.744C42.6755 41.1326 43.5761 40.8765 43.9647 40.1613C44.3532 39.4548 44.0883 38.5542 43.3731 38.148C43.3554 38.1392 38.5254 35.4019 38.5254 35.4019C38.1457 35.1899 38.0132 34.7043 38.2252 34.3246C38.4371 33.9449 38.9227 33.8036 39.3024 34.0244L44.936 37.2032C45.6247 37.5917 46.5607 37.3003 46.9404 36.6116L46.9227 36.6381Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M37.6247 47.084C37.2273 47.7992 36.3267 48.0641 35.6114 47.6668L34.5342 47.0752L35.3554 45.5476C35.5585 45.1679 35.6733 44.7617 35.7086 44.3467L37.0331 45.0707C37.7483 45.4681 38.0132 46.3688 37.6159 47.084H37.6247Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M31.6997 48.0995C31.32 48.2142 30.9227 48.1789 30.5783 47.9935C29.8631 47.605 29.5894 46.7131 29.9691 45.9891L31.3554 43.4019C31.7351 42.6955 32.6534 42.4129 33.3598 42.7926C34.0574 43.1635 34.3487 44.0818 33.9691 44.797L32.5827 47.3842C32.3973 47.7286 32.0883 47.9847 31.7086 48.0995H31.6997Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M27.4966 46.3246C27.1169 46.4394 26.7196 46.4041 26.3752 46.2187C25.66 45.8301 25.3862 44.9383 25.7659 44.2143L27.3465 41.2562C27.5319 40.9118 27.841 40.6558 28.2207 40.541C28.6004 40.4262 28.9977 40.4615 29.3509 40.6469C29.6953 40.8324 29.9514 41.1414 30.0661 41.5211C30.1809 41.9008 30.1456 42.307 29.9602 42.6513L28.3796 45.6094C28.1942 45.9538 27.8851 46.2098 27.5054 46.3246H27.4966Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M23.117 44.8853C23.4967 44.7705 23.8057 44.5145 23.9911 44.1701L25.9426 40.5145C26.3223 39.8081 26.0397 38.8897 25.3333 38.51C24.6534 38.148 23.6998 38.4217 23.3377 39.1105L21.3775 42.775C20.9889 43.4902 21.2627 44.3908 21.9867 44.7794C22.3311 44.9648 22.7285 45.0001 23.1081 44.8853H23.117Z" fill="white" />
			</svg>
		</SvgIcon>;
});
BadgePartner.displayName = 'BadgePartner';