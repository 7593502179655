/*
| Developed by Senskle
| Filename: MLikePost.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MLikePostProps extends SvgIconProps {
  strikethrough?: boolean;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MLikePost: React.FC<MLikePostProps> = props => {
  if (props.strikethrough) {
    return <SvgIcon {...props} inheritViewBox>
				<svg viewBox="0 0 24 24">
					<path d="M22.5021 2.337C22.9641 1.875 22.9641 1.122 22.5021 0.66C22.0401 0.198 21.2871 0.198 20.8251 0.66L19.7421 1.743C18.3171 0.849 16.5471 0.561 14.8311 1.053C13.8081 1.347 12.8931 1.938 12.1401 2.694L11.8161 3.021L11.4021 2.604C10.2621 1.458 8.71708 0.813 7.10608 0.813C6.63508 0.813 6.17008 0.87 5.72008 0.975C4.25908 1.323 3.34408 2.82 3.82708 4.254C3.86308 4.365 3.90808 4.476 3.95608 4.587C4.07008 4.428 4.19608 4.278 4.33708 4.14C5.07208 3.399 6.06808 2.985 7.10608 2.985C8.14408 2.985 9.14308 3.402 9.87508 4.14L11.0541 5.325C11.4741 5.748 12.1581 5.748 12.5811 5.325L13.7001 4.197C14.1051 3.789 14.5821 3.447 15.1191 3.243C16.1451 2.853 17.2251 2.913 18.1491 3.336L9.53608 11.949C4.92208 7.554 -1.38692 3.636 5.21008 0C-3.24692 4.26 2.87908 9.138 7.59208 13.89L1.34908 20.133C0.887077 20.595 0.887077 21.348 1.34908 21.81C1.58008 22.041 1.88308 22.158 2.18908 22.158C2.49508 22.158 2.79508 22.041 3.02908 21.81L9.22408 15.615C11.7981 18.552 13.0701 21.405 9.16108 23.997C14.9631 20.253 13.9491 16.83 11.1831 13.653L19.9041 4.932C20.5731 6.084 20.6571 7.554 19.9461 8.874C19.7781 9.186 19.5501 9.465 19.2981 9.717L19.2771 9.738L13.9131 15.456C14.3421 16.224 14.6181 17.01 14.6751 17.808L20.8431 11.232C21.9711 10.086 22.6041 8.541 22.6041 6.927C22.6041 5.643 22.1961 4.404 21.4641 3.375L22.5021 2.337Z" fill="currentColor" />
				</svg>
			</SvgIcon>;
  }
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MLikePost" data-sentry-source-file="MLikePost.tsx">
			<svg viewBox="0 0 24 24" fill="currentColor" data-sentry-element="svg" data-sentry-source-file="MLikePost.tsx">
				<g clipPath="url(#clip0_734_31432)" data-sentry-element="g" data-sentry-source-file="MLikePost.tsx">
					<path d="M5.89804 2C-7.76603 8.19543 19.6865 15.952 9.55412 22C23.55 13.8734 -5.27759 7.54496 5.89804 2Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MLikePost.tsx" />
					<path fillRule="evenodd" clipRule="evenodd" d="M21.9993 7.77334C21.9993 6.42035 21.4059 5.12517 20.3499 4.16825C18.927 2.87886 16.8214 2.3527 14.802 2.87597C13.8544 3.12171 13.009 3.61318 12.3103 4.24342L12.0104 4.51517L11.6244 4.16536C10.5684 3.20844 9.13917 2.6736 7.64611 2.6736C7.21222 2.6736 6.78153 2.71986 6.3636 2.80948C5.01092 3.09858 4.1623 4.34749 4.60894 5.54148C4.64403 5.63399 4.68232 5.72651 4.72698 5.81902C4.83226 5.68892 4.9503 5.56172 5.07791 5.44608C5.75745 4.83029 6.67944 4.48337 7.64292 4.48337C8.60639 4.48337 9.52838 4.83029 10.2079 5.44608L11.299 6.4348C11.6882 6.78751 12.3199 6.78751 12.7123 6.4348L13.7492 5.49522C14.1256 5.15409 14.5659 4.87077 15.0636 4.7002C16.4513 4.22607 17.9476 4.55276 18.9334 5.44608C20.034 6.44637 20.3563 8.01907 19.5332 9.3923C19.3768 9.6525 19.1663 9.88378 18.9334 10.0948L18.9143 10.1122L13.947 14.8765C14.3457 15.5184 14.5978 16.1717 14.652 16.8367L20.3626 11.3553C21.4059 10.4013 21.9929 9.11188 21.9929 7.76756L21.9993 7.77334Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MLikePost.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MLikePost.tsx">
					<clipPath id="clip0_734_31432" data-sentry-element="clipPath" data-sentry-source-file="MLikePost.tsx">
						<rect width="20" height="20" fill="currentColor" transform="translate(2 2)" data-sentry-element="rect" data-sentry-source-file="MLikePost.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};