/*
| Developed by Dirupt
| Filename : user-notification.endpoint.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from '@/services/api/axios-instance'
import {
	UserNotification,
	UserNotificationType,
} from '@/services/api/modules/user-notification/user-notification.contract'

/*
|--------------------------------------------------------------------------
| User notification URLs
|--------------------------------------------------------------------------
*/
export const userNotificationEndpointUrls = {
	getNotifications: () => '/user/notification',
	getUnreadCount: () => '/user/notification/unread-count',
	markAllAsRead: () => '/user/notification/mark-all-as-read',
	markAsRead: (notificationId: string) => `/user/notification/${notificationId}/mark-as-read`,
	markAsUnread: (notificationId: string) => `/user/notification/${notificationId}/mark-as-unread`,
} as const

/*
|--------------------------------------------------------------------------
| User notification endpoint
|--------------------------------------------------------------------------
*/
export class UserNotificationEndpoint {
	// [GET] - /user/notification - (AUTH)
	// ----------------------------------------------------------------------------
	public static getNotifications(options?: AxiosRequestConfig) {
		return axiosInstance<Array<UserNotification>>({
			...options,
			url: userNotificationEndpointUrls.getNotifications(),
			method: 'GET',
		})
	}

	// [GET] - /user/notification/unread-count - (AUTH)
	// ----------------------------------------------------------------------------
	public static getUnreadCount(options?: AxiosRequestConfig) {
		return axiosInstance<number>({
			...options,
			url: userNotificationEndpointUrls.getUnreadCount(),
			method: 'GET',
		})
	}

	// [POST] - /user/notification/mark-all-as-read - (AUTH)
	// ----------------------------------------------------------------------------
	public static markAllAsRead(types?: Array<UserNotificationType>, options?: AxiosRequestConfig) {
		return axiosInstance<void>({
			...options,
			url: userNotificationEndpointUrls.markAllAsRead(),
			method: 'POST',
			data: types ? { types } : undefined,
		})
	}

	// [POST] - /user/notification/:notificationId/mark-as-read - (AUTH)
	// ----------------------------------------------------------------------------
	public static markAsRead(notificationId: string, options?: AxiosRequestConfig) {
		return axiosInstance<void>({
			...options,
			url: userNotificationEndpointUrls.markAsRead(notificationId),
			method: 'POST',
		})
	}

	// [POST] - /user/notification/:notificationId/mark-as-unread - (AUTH)
	// ----------------------------------------------------------------------------
	public static markAsUnread(notificationId: string, options?: AxiosRequestConfig) {
		return axiosInstance<void>({
			...options,
			url: userNotificationEndpointUrls.markAsUnread(notificationId),
			method: 'POST',
		})
	}
}
