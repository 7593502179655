/*
| Developed by Senskle
| Filename: MMoon.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MMoonProps extends SvgIconProps {
  variant?: 'filled' | 'outlined';
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MMoon: React.FC<MMoonProps> = ({
  variant = 'outlined',
  ...props
}) => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MMoon" data-sentry-source-file="MMoon.tsx">
			{variant === 'filled' ? <svg viewBox="0 0 24 24" fill="none">
					<path d="M12.625 22H6.375C6.20924 22 6.05027 21.9298 5.93306 21.8047C5.81585 21.6797 5.75 21.5101 5.75 21.3333C5.75 21.1565 5.81585 20.987 5.93306 20.8619C6.05027 20.7369 6.20924 20.6667 6.375 20.6667H12.625C12.7908 20.6667 12.9497 20.7369 13.0669 20.8619C13.1842 20.987 13.25 21.1565 13.25 21.3333C13.25 21.5101 13.1842 21.6797 13.0669 21.8047C12.9497 21.9298 12.7908 22 12.625 22ZM13.875 19.3333H5.125C4.95924 19.3333 4.80027 19.2631 4.68306 19.1381C4.56585 19.0131 4.5 18.8435 4.5 18.6667C4.5 18.4899 4.56585 18.3203 4.68306 18.1953C4.80027 18.0702 4.95924 18 5.125 18H13.875C14.0408 18 14.1997 18.0702 14.3169 18.1953C14.4342 18.3203 14.5 18.4899 14.5 18.6667C14.5 18.8435 14.4342 19.0131 14.3169 19.1381C14.1997 19.2631 14.0408 19.3333 13.875 19.3333ZM21.375 16.6667H2.625C2.45924 16.6667 2.30027 16.5964 2.18306 16.4714C2.06585 16.3464 2 16.1768 2 16C2 15.8232 2.06585 15.6536 2.18306 15.5286C2.30027 15.4036 2.45924 15.3333 2.625 15.3333H4.91812C4.47896 13.7893 4.38488 12.157 4.64341 10.5673C4.90194 8.97749 5.5059 7.47438 6.40693 6.17833C7.30795 4.88227 8.48099 3.82929 9.83209 3.10373C11.1832 2.37816 12.6748 2.00016 14.1875 2.00002C15.7523 1.99683 17.2941 2.40242 18.6781 3.18135C16.3659 3.96468 14.4185 5.65482 13.2293 7.9104C12.0401 10.166 11.6977 12.8189 12.2712 15.3333H21.375C21.5408 15.3333 21.6997 15.4036 21.8169 15.5286C21.9342 15.6536 22 15.8232 22 16C22 16.1768 21.9342 16.3464 21.8169 16.4714C21.6997 16.5964 21.5408 16.6667 21.375 16.6667Z" fill={props.fill ?? 'white'} />
				</svg> : <svg viewBox="0 0 24 24" fill="none">
					<path d="M12.625 22H6.375C6.20924 22 6.05027 21.9298 5.93306 21.8047C5.81585 21.6797 5.75 21.5101 5.75 21.3333C5.75 21.1565 5.81585 20.987 5.93306 20.8619C6.05027 20.7369 6.20924 20.6667 6.375 20.6667H12.625C12.7908 20.6667 12.9497 20.7369 13.0669 20.8619C13.1842 20.987 13.25 21.1565 13.25 21.3333C13.25 21.5101 13.1842 21.6797 13.0669 21.8047C12.9497 21.9298 12.7908 22 12.625 22ZM13.875 19.3333H5.125C4.95924 19.3333 4.80027 19.2631 4.68306 19.1381C4.56585 19.0131 4.5 18.8435 4.5 18.6667C4.5 18.4899 4.56585 18.3203 4.68306 18.1953C4.80027 18.0702 4.95924 18 5.125 18H13.875C14.0408 18 14.1997 18.0702 14.3169 18.1953C14.4342 18.3203 14.5 18.4899 14.5 18.6667C14.5 18.8435 14.4342 19.0131 14.3169 19.1381C14.1997 19.2631 14.0408 19.3333 13.875 19.3333ZM21.375 16.6667H2.625C2.45924 16.6667 2.30027 16.5964 2.18306 16.4714C2.06585 16.3464 2 16.1768 2 16C2 15.8232 2.06585 15.6536 2.18306 15.5286C2.30027 15.4036 2.45924 15.3333 2.625 15.3333H4.91813C4.47896 13.7893 4.38488 12.157 4.64341 10.5673C4.90194 8.97749 5.5059 7.47438 6.40693 6.17833C7.30795 4.88227 8.48099 3.8293 9.83209 3.10373C11.1832 2.37816 12.6748 2.00017 14.1875 2.00002C15.7524 1.9967 17.2942 2.40229 18.6781 3.18135C16.3659 3.96468 14.4185 5.65482 13.2293 7.9104C12.0401 10.166 11.6977 12.8189 12.2712 15.3333H21.375C21.5408 15.3333 21.6997 15.4036 21.8169 15.5286C21.9342 15.6536 22 15.8232 22 16C22 16.1768 21.9342 16.3464 21.8169 16.4714C21.6997 16.5964 21.5408 16.6667 21.375 16.6667ZM14.3213 3.16669C13.0888 3.17036 11.8713 3.45446 10.75 4.00002C9.48026 4.67027 8.34267 5.59381 7.39875 6.72068C6.28937 8.18201 5.75 10.0667 5.75 12.484C5.75148 13.4722 5.96529 14.447 6.375 15.3333H10.8881C10.7727 14.6008 10.7127 13.8595 10.7087 13.1167C10.7328 10.9921 11.271 8.91015 12.2712 7.07268C13.0617 5.48908 14.2715 4.18861 15.75 3.33335C15.2816 3.21769 14.802 3.16175 14.3213 3.16669Z" fill={props.fill ?? 'white'} />
				</svg>}
		</SvgIcon>;
};