/*
| Developed by Dirupt
| Filename : useHistory.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useRouter } from 'next/router';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ReturnType = {
  history: string[];
  canGoBack: () => boolean;
};

/*
|--------------------------------------------------------------------------
| Context
|--------------------------------------------------------------------------
*/
export const HistoryManagerContext = React.createContext<ReturnType>(null!);

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useHistory = () => React.useContext(HistoryManagerContext);

/*
|--------------------------------------------------------------------------
| Hook manager
|--------------------------------------------------------------------------
*/
export function useHistoryManager() {
  const router = useRouter();
  const [history, setHistory] = React.useState<string[]>([]);
  React.useEffect(() => {
    const handleRouteChange = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      if (!shallow) {
        setHistory(prevState => [...prevState, url]);
      }
    };
    router.beforePopState(() => {
      setHistory(prevState => prevState.slice(0, -2));
      return true;
    });
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);
  return {
    history,
    canGoBack: () => history.length > 1
  };
}