/*
| Developed by Dirupt
| Filename : commonSlice.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaletteMode } from '@mui/material'
import { RootState } from '@/stores/store'
import { CookiesService } from '@/services/cookies/cookies.service'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface CommonAppBarBreadcrumbs {
	name: string
	href?: string
	alt?: string
}
export interface CommonState {
	themes: {
		mode: PaletteMode
	}
}

/*
|--------------------------------------------------------------------------
| INIT STATE
|--------------------------------------------------------------------------
*/
const initialState: CommonState = {
	themes: {
		mode: 'dark',
	},
}

/*
|--------------------------------------------------------------------------
| SLICE
|--------------------------------------------------------------------------
*/
export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setThemeMode: (state, action: PayloadAction<PaletteMode>) => {
			state.themes.mode = action.payload
			CookiesService.setCookie('senskle-color-mode', action.payload)
		},
	},
})

/*
|--------------------------------------------------------------------------
| EXPORT SELECTORS
|--------------------------------------------------------------------------
*/
export const selectThemeMode = (state: RootState) => state.common.themes.mode

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export const { setThemeMode } = commonSlice.actions
export default commonSlice.reducer
