/*
| Developed by Dirupt
| Filename : dialog.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const dialogOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiDialog: {
		styleOverrides: {
			paper: {
				backgroundColor: theme.palette.background.paper,
				backgroundImage: 'none',
				padding: theme.spacing(1),
			},
		},
	},
})
