/*
| Developed by Dirupt
| Filename : useGetCanonialUrl.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Router, useRouter } from 'next/router';
import { DiruptUtils } from '@/utils';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useGetCanonialUrl = (router?: Router) => {
  const localRouter = useRouter();
  const canonicalPath = React.useMemo(() => {
    return (router ?? localRouter).pathname === '/' ? '' : (router ?? localRouter).pathname;
  }, [localRouter, router]);
  const url = React.useMemo(() => {
    return `${DiruptUtils.getURL()}${canonicalPath}`;
  }, [canonicalPath]);
  return {
    canonicalPath,
    url,
    canonical: DiruptUtils.getURL()
  };
};