/*
| Developed by Dirupt
| Filename : link.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const linkOverrides = (): Components<Omit<Theme, 'components'>> => ({
	MuiLink: {
		styleOverrides: {
			root: {
				textDecoration: 'none',
				fontSize: 14,
				fontStyle: 'normal',
				fontWeight: 500,
				lineHeight: 'normal',
			},
		},
	},
})
