/*
| Developed by Dirupt
| Filename : useWindowSize.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
interface ReturnWidthHeight {
  width: number;
  height: number;
}

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useWindowSize = (): ReturnWidthHeight => {
  const [windowSize, setWindowSize] = React.useState<ReturnWidthHeight>({
    width: 0,
    height: 0
  });
  React.useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }
    handleResize();
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
  return windowSize;
};