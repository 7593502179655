import { alpha, Components, Theme } from '@mui/material'

export const textFieldOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiTextField: {
		styleOverrides: {
			root: {
				'& .MuiOutlinedInput-root': {
					borderRadius: '30px',
					backgroundColor:
						theme.palette.mode === 'light'
							? theme.palette.background.default
							: theme.palette.secondary.main,
					color: theme.palette.text.primary,
					'& .MuiOutlinedInput-notchedOutline': {
						// borderColor: alpha(
						// 	theme.palette.mode === 'light'
						// 		? theme.palette.secondary.dark
						// 		: theme.palette.text.primary,
						// 	0.3,
						// ),
						// borderColor: 'transparent',
					},
					'&:hover': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor:
								theme.palette.mode === 'light'
									? theme.palette.neutral.lightGrey
									: theme.palette.neutral.grey,
						},
					},
					'&.Mui-disabled': {
						opacity: 0.5,
					},
					'&.Mui-error': {
						'&:hover': {
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: alpha(
									theme.palette.mode === 'light'
										? theme.palette.neutral.lightGrey
										: theme.palette.neutral.grey,
									0.3,
								),
							},
						},
						color: theme.palette.error.main,
					},
				},
				'& input': {
					padding: theme.spacing(2, 3),
					'&::placeholder': {
						color: theme.palette.text.primary,
						opacity: 1,
						fontStyle: 'italic',
					},
				},
			},
		},
	},
})
