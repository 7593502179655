/*
| Developed by Dirupt
| Filename : SNotice.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Box, Card, CardActions, CardActionsProps, CardProps, styled, Typography, TypographyProps } from '@mui/material';
import React from 'react';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SNoticeProps {
  cardProps?: CardProps;
  title?: string;
  titleProps?: TypographyProps;
  description?: string;
  descriptionProps?: TypographyProps;
  actions?: React.ReactNode;
  actionsProps?: CardActionsProps;
  mode?: 'horizontal' | 'vertical';
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/

const NoticeContainer = styled(Card, {
  shouldForwardProp: prop => prop !== 'mode'
})<CardProps & {
  mode: SNoticeProps['mode'];
}>(({
  theme,
  mode
}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  ...(mode === 'horizontal' ? {
    flexDirection: 'row',
    justifyContent: 'space-between'
  } : {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  })
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SNotice: React.FC<SNoticeProps> = ({
  title,
  description,
  actions,
  titleProps,
  descriptionProps,
  mode = 'horizontal',
  cardProps,
  actionsProps
}) => {
  return <NoticeContainer mode={mode} {...cardProps} data-sentry-element="NoticeContainer" data-sentry-component="SNotice" data-sentry-source-file="SNotice.tsx">
			<Box display={'flex'} flexDirection={'column'} data-sentry-element="Box" data-sentry-source-file="SNotice.tsx">
				{title ? <Typography variant="subtitle1" {...titleProps}>
						{title}
					</Typography> : null}
				{description ? <Typography variant="body1" {...descriptionProps}>
						{description}
					</Typography> : null}
			</Box>
			{actions ? <CardActions {...actionsProps}>{actions}</CardActions> : null}
		</NoticeContainer>;
};