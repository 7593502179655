/*
| Developed by Dirupt
| Filename : MotionContainer.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { m, MotionProps } from 'framer-motion';
import Box, { BoxProps } from '@mui/material/Box';
import { variantContainer } from './variants';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ExtendProps = BoxProps & MotionProps;
export interface MotionContainerProps extends ExtendProps {
  animate?: boolean;
  action?: boolean;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MotionContainer: React.FC<MotionContainerProps> = ({
  animate,
  action = false,
  children,
  ...other
}) => {
  if (action) {
    return <Box component={m.div} initial={false} animate={animate ? 'animate' : 'exit'} variants={variantContainer()} {...other}>
				{children}
			</Box>;
  }
  return <Box component={m.div} initial="initial" animate="animate" exit="exit" variants={variantContainer()} {...other} data-sentry-element="Box" data-sentry-component="MotionContainer" data-sentry-source-file="MotionContainer.tsx">
			{children}
		</Box>;
};