/*
| Developed by Dirupt
| Filename : useActiveLink.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useRouter } from 'next/router';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ReturnType = boolean;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

/**
 * Check if the current path is active
 * @param path
 * @param deep
 */
export const useActiveLink = (path: string, deep = true): ReturnType => {
  const {
    pathname
  } = useRouter();
  const checkPath = React.useMemo(() => path.startsWith('#'), [path]);
  const currentPath = React.useMemo(() => path === '/' ? '/' : `${path}`, [path]);
  const normalActive = React.useMemo(() => !checkPath && pathname === currentPath, [checkPath, pathname, currentPath]);
  const deepActive = React.useMemo(() => !checkPath && pathname.includes(currentPath), [checkPath, pathname, currentPath]);
  return deep ? deepActive : normalActive;
};

/**
 * Check if paths are active
 * @param paths
 */
export const useActiveLinks = (paths: string[]): ReturnType => {
  const {
    pathname
  } = useRouter();
  const active = React.useMemo(() => paths.some(path => pathname.includes(path)), [paths, pathname]);
  return active;
};