/*
| Developed by Dirupt
| Filename : axios-interceptors.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AxiosResponse, AxiosError } from 'axios'
import { withScope, captureException } from '@sentry/nextjs'
import { toast } from 'sonner'

/*
|--------------------------------------------------------------------------
| Response - Success
|--------------------------------------------------------------------------
*/
export const commonSuccessResponseInterceptor = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	return response
}

/*
|--------------------------------------------------------------------------
| Response - Error
|--------------------------------------------------------------------------
*/
export const commonErrorResponseInterceptor = (error: AxiosError) => {
	// Sentry error handling
	withScope((scope) => {
		scope.setTag('where', 'axios-interceptors')
		scope.setLevel('error')

		if (error?.response) {
			if (error.response?.status && ![401, 403, 404].includes(error.response.status)) {
				scope.setContext('response', {
					status: error.response.status,
					statusText: error.response.statusText,
					data: error.response.data,
				})
				captureException(new Error(`Request failed: ${error.config?.url ?? error.response.config.url}`), scope)
			}
		} else if (error?.request) {
			scope.setContext('request', {
				status: error.request.status,
				statusText: error.request.statusText,
				data: error.request.data,
			})
			captureException(
				new Error(`No response: ${error.config?.url ?? error.request.config?.url ?? 'Unknown'}`),
				scope,
			)
		} else {
			captureException(error, scope)
		}
	})

	// Console error handling
	if (error?.response) {
		if (error?.response?.status === 401) {
			console.error('[SENSKLE API] Unauthorized.')
		} else if (error?.response?.status === 403) {
			console.error('[SENSKLE API] Forbidden.')
		} else if (error?.response?.status === 404) {
			console.error('[SENSKLE API] Not found.')
		} else if (error?.response?.status === 500) {
			console.error('[SENSKLE API] Internal server error.')
		} else if (error?.response?.status === 503) {
			console.error('[SENSKLE API] Service unavailable.')
		} else if (error?.response?.status === 429) {
			toast.error('Vous avez envoyé trop de requête, veuillez réessayer dans quelques minutes.')
			return Promise.reject(error)
		} else {
			console.error('[SENSKLE API] Unknown error.')
		}
		return Promise.reject(error)
	} else if (error?.request) {
		console.error('[SENSKLE API] Request problem.')
	}

	return Promise.reject(error)
}
