/*
| Developed by Dirupt
| Filename : SFooter.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SFooterProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const FooterContainer = styled(Box)<BoxProps>(({
  theme
}) => ({
  display: 'none',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    display: 'block',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral.white : theme.palette.neutral.black,
    color: theme.palette.mode === 'dark' ? theme.palette.neutral.black : theme.palette.neutral.white,
    padding: theme.spacing(3, 2, 2),
    textAlign: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      height: '8px',
      background: theme.palette.gradients.lgbt,
      backgroundPosition: '0 0',
      backgroundSize: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    }
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SFooter: React.FC<SFooterProps> = () => {
  const {
    t
  } = useTranslation();
  return <FooterContainer data-sentry-element="FooterContainer" data-sentry-component="SFooter" data-sentry-source-file="SFooter.tsx">
			<Typography data-sentry-element="Typography" data-sentry-source-file="SFooter.tsx">{t('common:footer.content')}</Typography>
		</FooterContainer>;
};