/*
| Developed by Senskle
| Filename: MClose.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MCloseProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MClose: React.FC<MCloseProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MClose" data-sentry-source-file="MClose.tsx">
			<svg viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="MClose.tsx">
				<g clipPath="url(#clip0_1247_33328)" data-sentry-element="g" data-sentry-source-file="MClose.tsx">
					<path d="M23 24C22.7434 24 22.4867 23.9034 22.2934 23.7067L12 13.4134L1.70669 23.7067C1.31669 24.0967 0.683354 24.0967 0.293354 23.7067C-0.0966455 23.3167 -0.0966455 22.6834 0.293354 22.2934L10.5867 12L0.293354 1.70669C-0.0966455 1.31669 -0.0966455 0.683354 0.293354 0.293354C0.683354 -0.0966455 1.31669 -0.0966455 1.70669 0.293354L12 10.5867L22.2934 0.293354C22.6834 -0.0966455 23.3167 -0.0966455 23.7067 0.293354C24.0967 0.683354 24.0967 1.31669 23.7067 1.70669L13.4134 12L23.7067 22.2934C24.0967 22.6834 24.0967 23.3167 23.7067 23.7067C23.51 23.9034 23.2567 24 23 24Z" fill={'currentColor'} data-sentry-element="path" data-sentry-source-file="MClose.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MClose.tsx">
					<clipPath id="clip0_1247_33328" data-sentry-element="clipPath" data-sentry-source-file="MClose.tsx">
						<rect width="24" height="24" fill={'currentColor'} data-sentry-element="rect" data-sentry-source-file="MClose.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};