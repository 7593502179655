/*
| Developed by Dirupt
| Filename : dirupt-utils.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { DateTime } from 'luxon'
import { PUBLIC_HOST } from '@/config/common.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type IFilters = {
	// eslint-disable-next-line no-unused-vars
	[key: string]: (value: any) => boolean
}

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
export class DiruptUtils {
	// URL Helpers
	// ----------------------------------------------------------------------------
	/**
	 * Get the current URL.
	 */
	static getURL(): string {
		const url =
			PUBLIC_HOST && PUBLIC_HOST !== ''
				? PUBLIC_HOST
				: process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
				? process.env.VERCEL_URL
				: 'https://app.senskle.com'

		return url.includes('http') ? url : `https://${url}`
	}

	/**
	 * Build with query string params a URL. If query param is null or undefined, it will be ignored.
	 */
	static buildURL<Params extends Record<string, string | number | boolean | null | undefined | object>>(
		url: string,
		params: Params,
	): string {
		const queryString = Object.keys(params)
			.filter((key) => params[key] !== null && params[key] !== undefined)
			.map((key) => {
				const value = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key]
				return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
			})
			.join('&')

		return `${url}?${queryString}`
	}

	/**
	 * Check if a URL is external.
	 */
	static isExternalURL(url: string): boolean {
		return url.includes('http')
	}

	// String
	// ----------------------------------------------------------------------------
	/**
	 * Capitalize the first letter of a string.
	 * @param str
	 */
	static capitalize(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	/**
	 * Capitalize the first letter of each word in a string.
	 * @param str
	 */
	static capitalizeAll(str: string) {
		return str
			.split(' ')
			.map((word) => this.capitalize(word))
			.join(' ')
	}

	/**
	 * Truncate a string.
	 * @param str
	 * @param length
	 */
	static truncate(str: string, length: number = 144) {
		return str.length > length ? str.substring(0, length) + '...' : str
	}

	// Case
	// ----------------------------------------------------------------------------
	/**
	 * Convert a string to camelCase.
	 * @param str
	 */
	static toCamelCase(str: string) {
		return str
			.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/[^a-z0-9-]/g, '')
	}

	/**
	 * Convert a string to snakeCase.
	 * @param str
	 */
	static toSnakeCase(str: string) {
		return str
			.toLowerCase()
			.replace(/\s+/g, '_')
			.replace(/[^a-z0-9_]/g, '')
	}

	// Array
	// ----------------------------------------------------------------------------
	/**
	 * Flatten an array.
	 * @param list
	 * @param key
	 */
	static flattenArray<T>(list: T[], key = 'children'): T[] {
		let children: T[] = []

		const flatten = list?.map((item: any) => {
			if (item[key] && item[key].length) {
				children = [...children, ...item[key]]
			}
			return item
		})

		return flatten?.concat(children.length ? this.flattenArray(children, key) : children)
	}

	// Crypto
	// ----------------------------------------------------------------------------
	/**
	 * Generate a random UUID.
	 */
	static uuidv4() {
		return crypto.randomUUID()
	}

	// Date
	// ----------------------------------------------------------------------------
	/**
	 * Format a date to a relative date with Luxon.
	 * @param date
	 */
	static formatRelativeDate(date: Date) {
		return DateTime.fromJSDate(date).toRelative()
	}

	/**
	 * Calculate the user's age from their date of birth.
	 * @param birthDate
	 */
	static calculateAge(birthDate: string) {
		const dateOfBirthObj = DateTime.fromISO(birthDate)

		const currentDate = DateTime.now()

		return Math.floor(currentDate.diff(dateOfBirthObj, 'years').years)
	}

	// Formik
	// ----------------------------------------------------------------------------
	static getChangedValues<T extends Record<string, any>>(values: T, initialValues: T) {
		return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
			const hasChanged = initialValues[key as keyof T] !== value

			if (hasChanged) {
				acc[key as keyof T] = value
			}

			return acc
		}, {})
	}

	static getChangedValuesExcludes<T extends Record<string, any>>(values: T, initialValues: T, excludes: string[]) {
		return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
			const hasChanged = initialValues[key as keyof T] !== value

			if (hasChanged && !excludes.includes(key)) {
				acc[key as keyof T] = value
			}

			return acc
		}, {})
	}

	// File
	// ----------------------------------------------------------------------------
	static isFile(value: unknown): value is File {
		return value instanceof File && value.size > 0
	}
}
