/*
| Developed by Senskle
| Filename: MDarkModeSwitch.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { Box, Switch, SwitchProps, useTheme } from '@mui/material';
import { MSun } from '@/components/common/svgs/icons/MSun';
import { MMoon } from '@/components/common/svgs/icons/MMoon';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { selectThemeMode, setThemeMode } from '@/stores/common/commonSlice';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MDarkModeSwitchProps extends SwitchProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MDarkModeSwitch: React.FC<MDarkModeSwitchProps> = props => {
  const theme = useTheme();
  const themeMode = useAppSelector(selectThemeMode);
  const dispatch = useAppDispatch();
  const isDark = React.useMemo(() => {
    return themeMode === 'dark';
  }, [themeMode]);

  // Methods
  // ----------------------------------------------------------------------------
  const toggleColorMode = () => {
    dispatch(setThemeMode(isDark ? 'light' : 'dark'));
  };

  // Render
  //--------------------------------------------------------------------------
  return <Box display="flex" alignItems="center" gap={1.5} data-sentry-element="Box" data-sentry-component="MDarkModeSwitch" data-sentry-source-file="MDarkModeSwitch.tsx">
			<MSun sx={{
      fontSize: 32
    }} variant="filled" fill={!isDark ? theme.palette.primary.main : '#ffffff4d'} data-sentry-element="MSun" data-sentry-source-file="MDarkModeSwitch.tsx" />
			<Switch {...props} value={isDark} checked={isDark} onClick={toggleColorMode} sx={{
      '& .MuiSwitch-track': {
        backgroundColor: 'primary.main'
      }
    }} data-sentry-element="Switch" data-sentry-source-file="MDarkModeSwitch.tsx" />
			<MMoon sx={{
      fontSize: 32
    }} variant="filled" fill={isDark ? 'white' : '#0909384D'} data-sentry-element="MMoon" data-sentry-source-file="MDarkModeSwitch.tsx" />
		</Box>;
};