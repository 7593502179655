import { alpha, Components, paperClasses, Theme } from '@mui/material'

export const selectOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiSelect: {
		styleOverrides: {
			root: {
				borderRadius: '30px',
				backgroundColor:
					theme.palette.mode === 'light' ? theme.palette.neutral.white : theme.palette.secondary.main,
				color: theme.palette.text.primary,
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor:
						theme.palette.mode === 'light' ? theme.palette.neutral.lightGrey : theme.palette.neutral.grey,
				},
				'&:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: alpha(
							theme.palette.mode === 'light'
								? theme.palette.neutral.lightGrey
								: theme.palette.neutral.grey,
							0.3,
						),
					},
				},
				'&.Mui-disabled': {
					opacity: 0.5,
				},
				'&.Mui-error': {
					'&:hover': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.error.main,
						},
					},
					color: theme.palette.error.main,
				},
				'& .MuiSelect-select': {
					padding: theme.spacing(2, 4, 2, 3),

					'&::placeholder': {
						color: theme.palette.text.primary,
						opacity: 1,
						fontStyle: 'italic',
					},
				},
				[`.${paperClasses.root}`]: {
					border: '1px solid red',
				},
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				color: theme.palette.text.primary,
				'&.Mui-selected': {
					backgroundColor: theme.palette.secondary.main,
					opacity: 1,
					color: theme.palette.secondary.contrastText,
					'&:hover': {
						backgroundColor: theme.palette.secondary.main,
					},
				},
			},
		},
	},
	MuiMenu: {
		styleOverrides: {
			paper: {
				backgroundColor:
					theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
				backgroundImage: 'none',
				borderRadius: theme.spacing(2),
				boxShadow: '0px 16px 32px 0px rgba(33, 33, 33, 0.20)',
			},
		},
	},
})
