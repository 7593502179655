/*
| Developed by Dirupt
| Filename : SMenuPartnerIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SMenuPartnerIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SMenuPartnerIcon: React.FC<SMenuPartnerIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SMenuPartnerIcon" data-sentry-source-file="SMenuPartnerIcon.tsx">
			<svg viewBox="0 0 90 80" data-sentry-element="svg" data-sentry-source-file="SMenuPartnerIcon.tsx">
				<g clipPath="url(#clip0_625_4)" data-sentry-element="g" data-sentry-source-file="SMenuPartnerIcon.tsx">
					<path d="M30.0854 43.8572L28.9545 42.7152C27.7117 41.4724 25.7075 41.4612 24.4535 42.6928C24.4535 42.6928 24.4423 42.704 24.4311 42.7152L17.6459 49.5227C16.3919 50.788 16.3919 52.8146 17.6459 54.0798L18.7768 55.2106C20.0084 56.4535 22.0238 56.4647 23.2778 55.233C23.2778 55.233 23.289 55.2218 23.3002 55.2106L30.0854 48.4031C31.3394 47.1491 31.3394 45.1113 30.0854 43.846V43.8572Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					<path d="M34.62 71.1211C35.874 72.3303 37.8671 72.3303 39.1323 71.1211L45.9174 64.3135C47.1603 63.0483 47.1603 61.0217 45.9174 59.7565L44.7866 58.6144C43.5214 57.4052 41.5284 57.4052 40.2743 58.6144L33.4892 65.422C32.2351 66.676 32.2351 68.7138 33.4892 69.979L34.62 71.1211Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					<path d="M42.536 79.0595C43.7789 80.3023 45.7831 80.3135 47.0371 79.0707C47.0371 79.0707 47.0371 79.0707 47.0483 79.0595L53.8223 72.2519C55.0763 70.9979 55.0763 68.9601 53.8223 67.7061L52.7026 66.564C52.098 65.9706 51.2918 65.6347 50.4409 65.6347C49.6011 65.6347 48.795 65.9706 48.2015 66.564L41.4164 73.3716C40.1735 74.6256 40.1735 76.6634 41.4164 77.9174L42.536 79.0707V79.0595Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					<path d="M28.9545 64.112C29.8055 64.112 30.6116 63.7761 31.2163 63.1714L38.0014 56.3639C39.2554 55.0987 39.2554 53.0721 38.0014 51.8069L36.8706 50.6648C35.6277 49.422 33.6123 49.4108 32.3583 50.6648L25.5731 57.4724C24.3191 58.7376 24.3191 60.7642 25.5731 62.0294L26.704 63.1603C27.2974 63.7649 28.1148 64.1008 28.9545 64.1008V64.112Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					<path d="M53.8334 31.3842L71.9272 49.5451C73.1924 50.7544 75.1854 50.7544 76.4395 49.5451L77.5703 48.4143C78.8243 47.1603 78.8243 45.1225 77.5703 43.8572L52.7026 18.8775C52.0756 18.2729 51.0791 18.2729 50.4521 18.8775L38.0126 31.3618C34.2841 35.1127 28.2155 35.1239 24.4759 31.3954C24.4759 31.3954 24.4535 31.373 24.4423 31.3618C22.5724 29.4808 22.5724 26.4465 24.4423 24.5654L42.4689 6.44927C32.3807 -2.55283 17.0077 -2.08257 7.47935 7.52415C-2.49686 17.5787 -2.49686 33.8027 7.47935 43.8684L13.7607 50.1722C14.0406 49.0749 14.6004 48.0784 15.3842 47.2722L22.1693 40.4647C24.655 37.9678 28.697 37.9566 31.1938 40.4423C31.1938 40.4423 31.205 40.4535 31.2162 40.4647L32.3471 41.6067C33.6571 42.9391 34.3289 44.7754 34.1721 46.634C36.0084 46.4885 37.8111 47.1379 39.1323 48.4143L40.2631 49.5563C41.5619 50.8663 42.2225 52.6802 42.0882 54.5276C42.2337 54.5276 42.3793 54.4829 42.5248 54.4829C44.2267 54.4829 45.8502 55.1547 47.0483 56.3639L48.1791 57.506C49.4891 58.8384 50.1609 60.6746 50.0042 62.5332C51.8404 62.3877 53.6431 63.0371 54.9643 64.3023L56.0616 65.422C56.0616 65.422 56.084 65.4332 56.0952 65.4444C57.338 66.6872 59.3422 66.6984 60.5962 65.4668C60.5962 65.4668 60.6074 65.4556 60.6186 65.4444L61.7495 64.3023C63.0035 63.0483 63.0035 61.0105 61.7495 59.7565L43.6669 41.5843C43.0399 40.9573 43.0399 39.9496 43.6669 39.3226C44.2939 38.6956 45.3016 38.6956 45.9286 39.3226L64.0224 57.4948C65.2876 58.704 67.2694 58.704 68.5346 57.4948L69.6655 56.3639C70.9195 55.0987 70.9195 53.0721 69.6655 51.8069L51.5829 33.6459C50.9559 33.0189 50.9559 32.0112 51.5829 31.3842C52.2099 30.7572 53.2176 30.7572 53.8446 31.3842H53.8334Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					<path d="M81.4668 44.4955L82.0938 43.8684C92.07 33.8139 92.07 17.5899 82.0938 7.52414C72.1624 -2.46326 56.028 -2.50805 46.0406 7.42337C46.007 7.45696 45.9734 7.49055 45.9398 7.52414L26.7152 26.8272C26.0882 27.4542 26.0882 28.4731 26.7152 29.1113C29.2344 31.5409 33.2316 31.5409 35.7509 29.1113L48.1904 16.627C50.0826 14.802 53.0833 14.802 54.9755 16.627L79.8433 41.5955C80.6382 42.4017 81.198 43.3982 81.478 44.4843L81.4668 44.4955Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="SMenuPartnerIcon.tsx">
					<clipPath id="clip0_625_4" data-sentry-element="clipPath" data-sentry-source-file="SMenuPartnerIcon.tsx">
						<rect width="89.5731" height="80" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="SMenuPartnerIcon.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};