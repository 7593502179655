/*
| Developed by Dirupt
| Filename : MSubscription.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MSubscriptionProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MSubscription: React.FC<MSubscriptionProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MSubscription" data-sentry-source-file="MSubscription.tsx">
			<svg viewBox="0 0 20 20" data-sentry-element="svg" data-sentry-source-file="MSubscription.tsx">
				<path fill={props?.fill ?? 'white'} d="M20 2.6875V16.8164C20 17.7656 19.1518 18.5039 18.0714 18.5039H1.92857C0.861607 18.5 0 17.7734 0 16.8125V2.6875C0 1.74609 0.839286 1 1.92857 1H18.0759C19.1473 1 20 1.73437 20 2.6875ZM18.3348 16.8125V2.6875C18.3348 2.57031 18.2188 2.46094 18.0759 2.46094H17.6607L12.7366 5.375L10 3.42578L7.26786 5.375L2.34375 2.45703H1.92857C1.78571 2.45703 1.66964 2.56641 1.66964 2.68359V16.8125C1.66964 16.9297 1.78571 17.0391 1.92857 17.0391H18.0759C18.2188 17.043 18.3348 16.9336 18.3348 16.8125ZM6.70536 7.01562V8.46094H3.42411V7.01562H6.70536ZM6.70536 9.92188V11.3789H3.42411V9.92188H6.70536ZM7.20089 4.16797L9.61161 2.46094H4.32143L7.20089 4.16797ZM16.5759 7.01562V8.46094H7.82589V7.01562H16.5759ZM16.5759 9.92188V11.3789H7.82589V9.92188H16.5759ZM12.7991 4.16797L15.6786 2.46094H10.3929L12.7991 4.16797ZM16.5759 12.8359V14.293H12.1384V12.8359H16.5759Z" data-sentry-element="path" data-sentry-source-file="MSubscription.tsx" />
			</svg>
		</SvgIcon>;
};