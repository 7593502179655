/*
| Developed by Dirupt
| Filename : seo.config.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { DefaultSeoProps } from 'next-seo/lib/types'
import { DiruptUtils } from '@/utils'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DefaultSeoPropsExtra extends DefaultSeoProps {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const DEFAULT_SEO_PROPS: Readonly<DefaultSeoPropsExtra> = Object.freeze<DefaultSeoPropsExtra>({
	title: 'Connexion • Authenticité • Expérience • Erotisme',
	titleTemplate: 'Senskle | %s',
	description: 'Connexion • Authenticité • Expérience • Erotisme.',
	canonical: DiruptUtils.getURL(),
	openGraph: {
		type: 'website',
		locale: 'en_US',
		siteName: 'Senskle',
		description: 'Connexion • Authenticité • Expérience • Erotisme.',
		images: [
			{
				url: `${DiruptUtils.getURL()}/preview.jpg`,
				alt: 'Senskle',
				width: 600,
				height: 600,
			},
		],
	},
	twitter: {
		handle: '@Senskle',
		site: '@Senskle',
		cardType: 'summary_large_image',
	},
	additionalLinkTags: [
		{
			rel: 'shortcut icon',
			href: '/favicons/favicon.ico',
		},
	],
	additionalMetaTags: [
		// App data for internal project
		{
			property: 'dirupt:app-name',
			content: 'Senskle',
		},
		{
			property: 'senskle:app-version',
			content: process.env.appVersion || process.env.NEXT_PUBLIC_APP_VERSION || '1.0.0',
		},
		// Other meta
		{
			httpEquiv: 'x-ua-compatible',
			content: 'IE=edge; chrome=1',
		},
	],
})
