/*
| Developed by Dirupt
| Filename : DesktopNavItems.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Grid, GridProps, styled } from '@mui/material';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav';
import { MHome, MHomeProps } from '@/components/common/svgs/icons/MHome';
import { MBell, MBellProps } from '@/components/common/svgs/icons/MBell';
import { MRooms, MRoomsProps } from '@/components/common/svgs/icons/MMessage';
import { useNotificationUnreadCount } from '@/services/api/modules/user-notification/hooks/useNotificationUnreadCount';
import { useUnreadRooms } from '@/services/api/modules/room/hooks/useUnreadRooms';
import { UrlsConfig } from '@/config/urls.config';
import { DesktopNavItem, DesktopNavItemProps } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItem';
import { DesktopNavButtonItem } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavButtonItem';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type DesktopNavItemKey = 'home' | 'rooms' | 'search' | 'notifications';
export interface DesktopNavItemsProps {}
interface DesktopNavButtonItemProps {
  type: 'button';
  component: (key: string) => React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ButtonsContainer = styled(Grid)<GridProps>(({
  theme
}) => ({
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
  position: 'relative',
  flex: 1,
  '@media (min-width: 360px)': {
    gap: theme.spacing(4)
  },
  '@media (min-width: 430px)': {
    gap: theme.spacing(5)
  }
}));
const HomeIcon = styled(MHome)<MHomeProps>(() => ({
  fill: 'currentColor',
  fontSize: 32
}));
const BellIcon = styled(MBell)<MBellProps>(() => ({
  fill: 'currentColor',
  fontSize: 32
}));
const RoomsIcon = styled(MRooms)<MRoomsProps>(() => ({
  fill: 'currentColor',
  fontSize: 32
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopNavItems: React.FC<DesktopNavItemsProps> = () => {
  const unreadNotifications = useNotificationUnreadCount();
  const unreadMessages = useUnreadRooms();

  // Nav items
  // ----------------------------------------------------------------------------
  const navItems = React.useMemo<Record<DesktopNavItemKey, DesktopNavItemProps | DesktopNavButtonItemProps>>(() => {
    return {
      home: {
        type: 'link',
        icon: props => <HomeIcon {...props} />,
        href: UrlsConfig.feed,
        activeBaseLink: UrlsConfig.feed
      },
      notifications: {
        type: 'link',
        icon: props => <BellIcon {...props} />,
        href: UrlsConfig.notifications.index,
        activeBaseLink: UrlsConfig.notifications.index,
        badge: unreadNotifications?.data ?? 0
      },
      rooms: {
        type: 'link',
        icon: props => <RoomsIcon {...props} />,
        href: UrlsConfig.rooms.index,
        activeBaseLink: UrlsConfig.rooms.index,
        badge: unreadMessages?.data ?? 0
      },
      search: {
        type: 'button',
        component: (key: string) => <DesktopNavButtonItem key={key} />
      }
    };
  }, [unreadMessages?.data, unreadNotifications?.data]);

  // Render
  // ----------------------------------------------------------------------------
  return <ButtonsContainer data-sentry-element="ButtonsContainer" data-sentry-component="DesktopNavItems" data-sentry-source-file="DesktopNavItems.tsx">
			{Object.entries(navItems).map(([key, item], index) => item.type === 'link' ? <DesktopNavItem key={`${key}-${index}`} {...item} /> : item.component(key))}
		</ButtonsContainer>;
};