/*
| Developed by Dirupt
| Filename : transition.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { TransitionHoverType, TransitionEnterType, TransitionExitType } from '../types'

/*
|--------------------------------------------------------------------------
| Variant - Transition - Hover
|--------------------------------------------------------------------------
*/
export const variantTransitionHover = (props?: TransitionHoverType) => {
	const duration = props?.duration || 0.32
	const ease = props?.ease || [0.43, 0.13, 0.23, 0.96]

	return { duration, ease }
}

/*
|--------------------------------------------------------------------------
| Variant - Transition - Enter
|--------------------------------------------------------------------------
*/
export const variantTransitionEnter = (props?: TransitionEnterType) => {
	const duration = props?.durationIn || 0.64
	const ease = props?.easeIn || [0.43, 0.13, 0.23, 0.96]

	return { duration, ease }
}

/*
|--------------------------------------------------------------------------
| Variant - Transition - Exit
|--------------------------------------------------------------------------
*/
export const variantTransitionExit = (props?: TransitionExitType) => {
	const duration = props?.durationOut || 0.48
	const ease = props?.easeOut || [0.43, 0.13, 0.23, 0.96]

	return { duration, ease }
}
