/*
| Developed by Dirupt
| Filename : casl.context.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react'
import { createContextualCan } from '@casl/react'
import { PureAbility } from '@casl/ability'
import { AppAbility } from '@/services/casl/casl.ability'

/*
|--------------------------------------------------------------------------
| Context
|--------------------------------------------------------------------------
*/
export const AbilityContext = React.createContext<AppAbility>(new PureAbility())
export const useAbility = () => React.useContext(AbilityContext)

/*
|--------------------------------------------------------------------------
| CASL Context
|--------------------------------------------------------------------------
*/
export const Can = createContextualCan(AbilityContext.Consumer)
