/*
| Developed by Dirupt
| Filename : tooltip.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const tooltipOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				backgroundColor:
					theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
				backgroundImage: 'none',
				borderRadius: 13,
				boxSizing: 'border-box',
				color: theme.palette.text.primary,
				padding: theme.spacing(1.5),
				boxShadow: theme.shadows[4],
			},
			arrow: {
				color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
			},
		},
	},
})
