/*
| Developed by Dirupt
| Filename : card.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const cardOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiCard: {
		styleOverrides: {
			root: {
				background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
				borderRadius: theme.spacing(2),
				boxShadow: '0px 8px 16px rgba(33, 33, 33, 0.07)',
				boxSizing: 'border-box',
				color: theme.palette.text.primary,
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				'&:last-child': {
					paddingBottom: theme.spacing(2),
				},
			},
		},
	},
})
