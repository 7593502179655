/*
| Developed by Dirupt
| Filename : SPageDefault.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, Container, ContainerProps, styled } from '@mui/material';
import { MPaddedBox } from '@/components/common/MPaddedBox';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';
import { DESKTOP_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/DesktopNav';
import { useResponsive } from '@/hooks/useResponsive';
import { SPageDefaultMobileHeader } from '@/components/layouts/pages/SPageDefault/SPageDefaultMobileHeader';
import { SPageDefaultDesktopHeader } from '@/components/layouts/pages/SPageDefault/SPageDefaultDesktopHeader';
import { PremiumPage, PremiumPageProps } from '@/components/common/PremiumPage';
import { SNotice, SNoticeProps } from '@/components/common/SNotice/SNotice';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SPageDefaultBreadcrumbsProps {
  label: string;
  url: string;
}
export interface SPageDefaultProps extends ContainerProps {
  children: React.ReactNode;
  contentProps?: BoxProps;
  title?: string;
  subTitle?: string;
  handleBack?: () => void;
  disabledPadding?: boolean;
  disabledGutters?: boolean;
  disabledBottomPadding?: boolean;
  fullHeight?: boolean;
  breadcrumbs?: Array<SPageDefaultBreadcrumbsProps>;
  headerProps?: BoxProps;
  headerActions?: React.ReactNode;
  isLocked?: boolean | Promise<boolean>;
  lockedPageProps?: PremiumPageProps;
  noticeProps?: SNoticeProps;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const PageContainer = styled(props => <Container maxWidth={'xl'} {...props} />, {
  shouldForwardProp: prop => prop !== 'fullHeight' && prop !== 'disabledBottomPadding'
})<ContainerProps & Pick<SPageDefaultProps, 'fullHeight' | 'disabledBottomPadding'>>(({
  theme,
  fullHeight,
  disabledBottomPadding
}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  paddingBottom: disabledBottomPadding ? 0 : theme.spacing(4),
  paddingLeft: 0,
  paddingRight: 0,
  ...(fullHeight ? {
    height: `calc(100vh - ${MOBILE_NAV_HEIGHT + 24}px)`
  } : {}),
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    ...(fullHeight ? {
      height: `calc(100vh - ${DESKTOP_NAV_HEIGHT}px)`
    } : {})
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SPageDefault: React.FC<SPageDefaultProps> = ({
  children,
  contentProps,
  title,
  subTitle,
  handleBack,
  disabledPadding,
  disabledGutters,
  disabledBottomPadding,
  fullHeight,
  breadcrumbs,
  headerProps,
  headerActions,
  isLocked,
  lockedPageProps,
  noticeProps,
  ...props
}) => {
  const isDesktopOnly = useResponsive('up', 'md');
  const Container = disabledPadding && !isDesktopOnly ? Box : MPaddedBox;
  return <PageContainer component={'main'} fullHeight={fullHeight} disabledBottomPadding={disabledBottomPadding} className={'page-default-layout'} {...props} data-sentry-element="PageContainer" data-sentry-component="SPageDefault" data-sentry-source-file="SPageDefault.tsx">
			{isLocked ? <PremiumPage {...lockedPageProps} /> : <React.Fragment>
					{title || subTitle ? !isDesktopOnly ? <SPageDefaultMobileHeader title={title} subTitle={subTitle} disabledGutters={disabledGutters} handleBack={handleBack} headerProps={headerProps} headerActions={headerActions} /> : <SPageDefaultDesktopHeader title={title} subTitle={subTitle} breadcrumbs={breadcrumbs} headerProps={headerProps} headerActions={headerActions} /> : null}
					<Container flex={1} display={'flex'} flexDirection={'column'} gap={2} width={'100%'} {...contentProps}>
						{noticeProps ? <SNotice {...noticeProps} /> : null}
						{children}
					</Container>
				</React.Fragment>}
		</PageContainer>;
};