/*
| Developed by Senskle
| Filename: MSearch.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MSearchProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MSearch: React.FC<MSearchProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MSearch" data-sentry-source-file="MSearch.tsx">
			<svg viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="MSearch.tsx">
				<path d="M22.9434 24C22.6629 24 22.401 23.894 22.2016 23.6977L18.3429 19.8919C18.879 19.4338 19.3808 18.9288 19.8358 18.3896L23.6821 22.1797C24.0997 22.5912 24.106 23.2644 23.6945 23.6821C23.4951 23.8847 23.227 23.9969 22.9403 23.9969L22.9434 24ZM10.1112 20.3003C4.53818 20.3003 0 15.7465 0 10.1486C0 4.55065 4.53818 0 10.1143 0C15.6904 0 20.2286 4.55377 20.2286 10.1486C20.2286 15.7434 15.6904 20.2971 10.1143 20.2971L10.1112 20.3003ZM10.1112 2.02286C5.64467 2.02286 2.01039 5.66961 2.01039 10.1517C2.01039 14.6338 5.64467 18.2805 10.1112 18.2805C14.5777 18.2805 18.2151 14.6338 18.2151 10.1517C18.2151 5.66961 14.5808 2.01974 10.1143 2.01974L10.1112 2.02286Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MSearch.tsx" />
			</svg>
		</SvgIcon>;
};