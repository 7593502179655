/*
| Developed by Dirupt
| Filename : accordion.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const accordionOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiAccordion: {
		styleOverrides: {
			root: {
				boxShadow: 'none',
				backgroundColor: 'transparent',
				backgroundImage: 'none',

				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			},
		},
	},
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				fontWeight: theme.typography.fontWeightMedium,
				padding: 0,
			},

			content: {
				position: 'relative',

				'&::before': {
					content: '""',
					position: 'absolute',
					bottom: -4,
					left: 0,
					width: theme.spacing(6),
					height: 3,
					backgroundColor: theme.palette.primary.main,
					borderRadius: 99,
				},
			},
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
})
