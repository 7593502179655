/*
| Developed by Senskle
| Filename: MFlame.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MFlameProps extends SvgIconProps {
  // variant?: 'filled' | 'outlined'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MFlame: React.FC<MFlameProps> = ({
  ...props
}) => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MFlame" data-sentry-source-file="MFlame.tsx">
			<svg viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="MFlame.tsx">
				<path d="M13.027 0V0.025226C13.2673 3.17679 12.631 6.3334 11.1894 9.13685C10.8215 5.59512 9.71957 4.28842 9.71957 4.28842C9.71957 4.28842 10.0029 7.02628 8.98551 8.57683C5.67808 13.6103 7.88358 16.222 7.88358 16.222C5.12794 16.222 5.31187 11.3736 5.31187 11.3736C5.31187 11.3736 0.902519 19.0187 8.25144 23.3054C8.77838 23.6132 9.39148 23.8486 10.0394 24C9.73117 23.8739 9.44782 23.7091 9.20589 23.4988C8.58782 22.9624 8.28127 22.1265 8.21333 21.3042C8.14539 20.4818 8.2879 19.6561 8.4304 18.8438C8.41549 20.6634 9.81402 22.3906 11.5754 22.7269C10.7685 21.6271 10.0294 20.3742 10.0245 19.0036C10.0212 17.9474 10.4553 16.9435 10.9557 16.0185C11.6964 14.6479 12.5929 13.3715 13.3286 11.9975C13.8041 11.1095 14.8779 9.2613 14.6791 8.20685C15.1033 10.4419 14.1008 12.687 13.3584 14.7303C12.6442 16.6979 12.1289 18.9817 13.1297 20.8165C14.0278 22.4612 15.211 19.165 15.3618 18.5512C15.5788 17.6716 15.7213 16.7551 15.781 15.8503C15.781 15.8503 16.9194 20.4717 16.288 22.1047C25.3155 12.8249 13.027 0 13.027 0Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="MFlame.tsx" />
			</svg>
		</SvgIcon>;
};