/*
| Developed by Dirupt
| Filename : usePremiumAbility.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useAbility } from '@/services/casl/casl.context';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const usePremiumAbility = () => {
  const ability = useAbility();
  return React.useMemo(() => ability.can('access', 'premiumContent'), [ability]);
};