/*
| Developed by Dirupt
| Filename : hooks.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './store'

/*
|--------------------------------------------------------------------------
| Hooks
|--------------------------------------------------------------------------
*/
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
