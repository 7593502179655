/*
| Developed by Dirupt
| Filename : partner.contract.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { User } from '@/services/api/modules/user/user.contract'
import { UserMediaFolder } from '@/services/api/modules/user-media-folder/user-media-folder.contract'

/*
|--------------------------------------------------------------------------
| Enums
|--------------------------------------------------------------------------
*/

export enum PartnerStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	LOCKED = 'LOCKED',
}

export enum PartnerCategory {
	CLUB_SAUNA = 'CLUB_SAUNA',
	LOVE_ROOM = 'LOVE_ROOM',
	PHOTOGRAPHER = 'PHOTOGRAPHER',
	CREATOR = 'CREATOR',
	ARTIST = 'ARTIST',
	PERFORMER = 'PERFORMER',
	SEXO = 'SEXO',
	ALL = 'ALL',
}

export enum PartnerReviewStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

/*
|--------------------------------------------------------------------------
| Partner reviews
|--------------------------------------------------------------------------
*/
export type UserReviewPartner = {
	// Fields
	id: string
	status: PartnerReviewStatus
	note: number
	review?: string
	partnerAnswer?: string
	// Timestamps
	createdAt: string
	updatedAt: string
	// Computed
	answered: boolean
	// Relations
	userId: string
	user: User
	partnerId: string
}

/*
|--------------------------------------------------------------------------
| Partner
|--------------------------------------------------------------------------
*/
export type Partner = {
	// Fields
	id: string
	status: PartnerStatus
	category: PartnerCategory
	name: string
	description?: string
	location?: [number, number] // [latitude, longitude]
	street1?: string
	street2?: string
	city?: string
	region?: string
	postalCode?: string
	country?: string
	phoneNumber?: string
	email?: string
	website?: string
	avatarId: string
	// Timestamps
	createdAt: string
	updatedAt: string
	// Computed
	avatarUrl: string
	isPending: boolean
	isActive: boolean
	isInactive: boolean
	isLocked: boolean
	medianNote: number | null
	// Relations
	userId: string
	user: User
	mediaFolders: Array<UserMediaFolder>
	reviews: Array<UserReviewPartner>
}
