/*
| Developed by Dirupt
| Filename : useNotificationUnreadCount.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import useSWR from 'swr';
import { UserNotificationEndpoint, userNotificationEndpointUrls } from '@/services/api/modules/user-notification/user-notification.endpoint';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useNotificationUnreadCount = () => {
  return useSWR(userNotificationEndpointUrls.getUnreadCount(), () => UserNotificationEndpoint.getUnreadCount().then(res => res.data));
};