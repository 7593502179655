/*
| Developed by Dirupt
| Filename : UserNavSection.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, List } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { domAnimation, LazyMotion } from 'framer-motion';
import { UserMenuItem, UserMenuItemProps } from '@/components/modules/user/menu';
import { useActiveLinks } from '@/hooks/useActiveLink';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type NavSectionParameters = {
  id: 'parameters';
  subNav: Record<'accountInformations' | 'password' | 'profils' | 'filters', UserMenuItemProps>;
};
type NavSectionAdminSpace = {
  id: 'adminSpace';
  subNav: Record<'redeemCodes', UserMenuItemProps>;
};
type NavSectionAmbassadorSpace = {
  id: 'ambassadorSpace';
  subNav: Record<'myAmbassadorSpace' | 'myCommunity' | 'events', UserMenuItemProps>;
};
type NavSectionPartnerSpace = {
  id: 'partnerSpace';
  subNav: Record<'myPartnerSpace' | 'events', UserMenuItemProps>;
};
type NavSectionFeatures = {
  id: 'features';
  subNav: Record<'crushes' | 'mediaFolderRequests' | 'blacklist' | 'questions' | 'events', UserMenuItemProps>;
  // subNav: Record<
  // 	'events' | 'crushes' | 'mediaFolderRequests' | 'blacklist' | 'questions' | 'settings',
  // 	UserMenuItemProps
  // >
};
type NavSectionSubscription = {
  id: 'subscription';
  subNav: Record<'subscription', UserMenuItemProps>;
  // subNav: Record<'subscription' | 'rewards', UserMenuItemProps>
};
type NavSectionLegals = {
  id: 'legals';
  subNav: Record<'policy' | 'cguCgv' | 'codeOfConduct' | 'faq', UserMenuItemProps>;
};
type NavSectionLogout = {
  id: 'logout';
  subNav: Record<'logout', UserMenuItemProps>;
};
export type UserNavSectionProps = {
  label: string;
  activeHref?: string;
  hideIf?: boolean;
} & (NavSectionParameters | NavSectionAdminSpace | NavSectionAmbassadorSpace | NavSectionPartnerSpace | NavSectionFeatures | NavSectionSubscription | NavSectionLegals | NavSectionLogout) & Omit<AccordionProps, 'children'>;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export function UserNavSection({
  id,
  label,
  activeHref,
  subNav,
  hideIf,
  ...accordionProps
}: UserNavSectionProps) {
  // Check if all subNav items href is an active route
  // ----------------------------------------------------------------------------
  const active = useActiveLinks(Object.values(subNav).filter(item => item.type === 'link')
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  .map(item => activeHref || item.href as string));

  // Render
  // ----------------------------------------------------------------------------
  if (hideIf) return null;
  return <Accordion disableGutters {...accordionProps} defaultExpanded={accordionProps?.defaultExpanded ?? active} data-sentry-element="Accordion" data-sentry-component="UserNavSection" data-sentry-source-file="UserNavSection.tsx">
			<AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${id}-content`} id={`${id}-header`} data-sentry-element="AccordionSummary" data-sentry-source-file="UserNavSection.tsx">
				{label}
			</AccordionSummary>
			<AccordionDetails id={`${id}-content`} data-sentry-element="AccordionDetails" data-sentry-source-file="UserNavSection.tsx">
				<LazyMotion features={domAnimation} data-sentry-element="LazyMotion" data-sentry-source-file="UserNavSection.tsx">
					<List data-sentry-element="List" data-sentry-source-file="UserNavSection.tsx">
						{Object.entries(subNav).map(([key, item]) => <UserMenuItem key={key} {...item} />)}
					</List>
				</LazyMotion>
			</AccordionDetails>
		</Accordion>;
}