import { Components, Theme } from '@mui/material'

export const tabOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiTab: {
		styleOverrides: {
			root: {
				color: theme.palette.text.primary,
			},
		},
	},
})
