/*
| Developed by Dirupt
| Filename : MFAQ.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MFAQProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MFAQ: React.FC<MFAQProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MFAQ" data-sentry-source-file="MFAQ.tsx">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="MFAQ.tsx">
				<g clipPath="url(#clip0_1247_33415)" data-sentry-element="g" data-sentry-source-file="MFAQ.tsx">
					<path d="M12 24C5.38207 24 0 18.6179 0 12C0 5.38207 5.38207 0 12 0C18.6179 0 24 5.38207 24 12C24 18.6179 18.6179 24 12 24ZM12 1.93103C6.4469 1.93103 1.93103 6.4469 1.93103 12C1.93103 17.5531 6.4469 22.069 12 22.069C17.5531 22.069 22.069 17.5531 22.069 12C22.069 6.4469 17.5531 1.93103 12 1.93103Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MFAQ.tsx" />
					<path d="M12 14.9352C11.4676 14.9352 11.0345 14.5021 11.0345 13.9697V12C11.0345 11.4676 11.4676 11.0345 12 11.0345C12.3945 11.0345 12.7779 10.9186 13.1062 10.6979C13.4345 10.4772 13.6883 10.1683 13.84 9.80414C13.9917 9.44 14.0303 9.04276 13.9531 8.65379C13.8759 8.26483 13.6883 7.91448 13.4097 7.6331C13.131 7.35448 12.7779 7.1669 12.3917 7.08966C12.0055 7.01241 11.6055 7.05104 11.2414 7.20276C10.8772 7.35448 10.5683 7.60828 10.3476 7.93655C10.1297 8.26483 10.011 8.64828 10.011 9.04276C10.011 9.57517 9.57793 10.0083 9.04552 10.0083C8.51311 10.0083 8.08 9.57517 8.08 9.04276C8.08 8.26483 8.30897 7.51172 8.74207 6.86345C9.17517 6.21517 9.78207 5.71586 10.5021 5.41793C11.2221 5.12 12.0055 5.04276 12.7669 5.19448C13.5283 5.34621 14.2235 5.71586 14.7752 6.26759C15.3241 6.81931 15.6966 7.51172 15.8483 8.27586C16 9.04 15.9228 9.82345 15.6248 10.5407C15.3269 11.2579 14.8276 11.8676 14.1821 12.3007C13.8069 12.5517 13.3986 12.731 12.9683 12.8414V13.9669C12.9683 14.4993 12.5352 14.9324 12.0028 14.9324L12 14.9352Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MFAQ.tsx" />
					<path d="M12.0993 17.52C11.8069 17.52 11.5228 17.6055 11.2772 17.7683C11.0345 17.9311 10.8441 18.1628 10.7338 18.4304C10.6207 18.7007 10.5931 18.9986 10.651 19.2855C10.709 19.5724 10.8497 19.8345 11.0565 20.0414C11.2634 20.2483 11.5255 20.389 11.8124 20.4469C12.0993 20.5048 12.3972 20.4745 12.6648 20.3642C12.9352 20.2511 13.1669 20.0635 13.3269 19.8207C13.4897 19.578 13.5752 19.2911 13.5752 18.9986C13.5697 18.6097 13.4124 18.2345 13.1365 17.9586C12.8607 17.6828 12.4883 17.5255 12.0965 17.52H12.0993Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MFAQ.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MFAQ.tsx">
					<clipPath id="clip0_1247_33415" data-sentry-element="clipPath" data-sentry-source-file="MFAQ.tsx">
						<rect width="24" height="24" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="rect" data-sentry-source-file="MFAQ.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};