/*
| Developed by Senskle
| Filename: MBell.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MBellProps extends SvgIconProps {
  // variant?: 'filled' | 'outlined'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MBell: React.FC<MBellProps> = ({
  ...props
}) => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MBell" data-sentry-source-file="MBell.tsx">
			<svg viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="MBell.tsx">
				<path d="M12 24C11.013 24 10.056 23.679 9.291 23.097C8.526 22.515 8.001 21.702 7.8 20.802H0L3.429 14.403V8.004C3.429 5.883 4.332 3.846 5.94 2.346C7.548 0.843 9.726 0 12 0C14.274 0 16.455 0.843 18.06 2.343C19.668 3.843 20.571 5.877 20.571 8.001V14.4L24 20.799H16.2C15.999 21.702 15.474 22.512 14.709 23.094C13.944 23.676 12.987 23.997 12 23.997V24ZM9.585 20.799C9.759 21.267 10.086 21.672 10.518 21.957C10.95 22.242 11.469 22.398 11.997 22.398C12.525 22.398 13.044 22.245 13.476 21.957C13.908 21.669 14.235 21.264 14.409 20.799H9.582H9.585ZM12 1.599C10.182 1.599 8.439 2.277 7.155 3.477C5.868 4.677 5.145 6.303 5.145 8.001V14.4L2.574 19.2H21.432L18.861 14.4V8.001C18.861 6.303 18.135 4.677 16.851 3.477C15.564 2.277 13.821 1.602 12.003 1.599H12Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MBell.tsx" />
			</svg>
		</SvgIcon>;
};