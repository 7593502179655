/*
| Developed by Dirupt
| Filename : useUnreadRooms.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import useSWR from 'swr';
import { RoomEndpoint, roomEndpointUrls } from '@/services/api/modules/room/room.endpoint';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useUnreadRooms = () => {
  return useSWR(roomEndpointUrls.getUnread(), () => RoomEndpoint.getUnread().then(res => res.data));
};