/*
| Developed by Dirupt
| Filename : common.config.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

/*
|--------------------------------------------------------------------------
| Commons
|--------------------------------------------------------------------------
*/
export const PUBLIC_HOST = process.env.NEXT_PUBLIC_HOST as string
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION as string
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV as string
export const IN_DEV = APP_ENV === 'development'

/*
|--------------------------------------------------------------------------
| Analytics
|--------------------------------------------------------------------------
*/
export const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY as string
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string

/*
|--------------------------------------------------------------------------
| AUTH
|--------------------------------------------------------------------------
*/
export const COOKIE_SESSION_NAME = process.env.NEXT_PUBLIC_COOKIE_SESSION_NAME as string

/*
|--------------------------------------------------------------------------
| API
|--------------------------------------------------------------------------
*/
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST as string

/*
|--------------------------------------------------------------------------
| MERCURE
|--------------------------------------------------------------------------
*/
export const MERCURE_HOST = process.env.NEXT_PUBLIC_MERCURE_HOST as string
