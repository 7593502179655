/*
| Developed by Dirupt
| Filename: MButton.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MButtonProps extends ButtonProps {
  enableGradient?: boolean;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ButtonStyled = styled(Button, {
  shouldForwardProp: propName => propName !== 'enableGradient'
})<MButtonProps>(({
  theme,
  enableGradient,
  variant
}) => ({
  ...(variant === 'contained' && enableGradient && {
    background: theme.palette.gradients.pink
  })
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MButton = <E,>({
  children,
  ...props
}: MButtonProps & E) => {
  // Render
  //--------------------------------------------------------------------------
  return <ButtonStyled {...props} data-sentry-element="ButtonStyled" data-sentry-component="MButton" data-sentry-source-file="MButton.tsx">{children}</ButtonStyled>;
};