/*
| Developed by Dirupt
| Filename : SMenuEventIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SMenuEventIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SMenuEventIcon: React.FC<SMenuEventIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SMenuEventIcon" data-sentry-source-file="SMenuEventIcon.tsx">
			<svg viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="SMenuEventIcon.tsx">
				<path d="M11.0805 7.58692L8.18124 6.79992C8.02507 6.75752 7.86194 6.83825 7.79896 6.98917L5.52748 12.4325L5.52554 12.4373C4.97968 13.8038 5.62529 15.3605 6.97053 15.9214L5.67247 20.8319L4.40794 20.4886C4.23509 20.4417 4.05746 20.5456 4.01113 20.7207C3.9648 20.8959 4.06738 21.0759 4.24024 21.1229L7.42851 21.9884C7.60116 22.036 7.77924 21.9327 7.82618 21.7577C7.87311 21.5827 7.77126 21.4023 7.59857 21.3547C7.59776 21.3545 7.59695 21.3543 7.59614 21.3541L6.29836 21.0018L7.59512 16.0959C9.04077 16.3111 10.3848 15.2979 10.5971 13.8328C10.598 13.8268 10.5989 13.8208 10.5997 13.8147C10.5999 13.813 10.6001 13.8113 10.6004 13.8096L11.3181 7.94456C11.3381 7.78195 11.2367 7.62931 11.0805 7.58692Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="SMenuEventIcon.tsx" />
				<path d="M19.9811 17.0279C19.9211 16.8571 19.7357 16.768 19.5672 16.8288C19.5662 16.8292 19.5654 16.8295 19.5644 16.8299L19.5645 16.8297L18.3009 17.2926L16.597 12.5163C17.9043 11.8555 18.4355 10.2457 17.7833 8.92084C17.7806 8.91522 17.7778 8.9096 17.7749 8.90398C17.7742 8.90246 17.7734 8.90094 17.7726 8.89942L15.0523 3.67165C14.9769 3.52665 14.8075 3.46024 14.6554 3.51602L11.8328 4.5503C11.6808 4.606 11.5925 4.76676 11.626 4.92708L12.8337 10.709C12.834 10.7107 12.8344 10.7124 12.8347 10.7141C13.1584 12.1513 14.5659 13.0543 15.9863 12.736L17.6916 17.516L16.4604 17.9671C16.2921 18.0288 16.205 18.217 16.2658 18.3875C16.3267 18.5581 16.5124 18.6463 16.6807 18.5847L19.7847 17.4474C19.9533 17.3865 20.0412 17.1987 19.9811 17.0279Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="SMenuEventIcon.tsx" />
				<path d="M10.2703 4.62675C10.2688 4.62322 10.2673 4.61969 10.2657 4.61621L9.33138 2.49925C9.26221 2.332 9.07235 2.25324 8.90735 2.32334C8.74231 2.39344 8.66459 2.58585 8.73377 2.75306C8.73575 2.7579 8.7379 2.76266 8.74008 2.76742L9.67417 4.88413C9.74432 5.05097 9.93463 5.12859 10.0992 5.05754C10.2639 4.98645 10.3404 4.79359 10.2703 4.62675Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="SMenuEventIcon.tsx" />
				<path d="M11.548 2.00588C11.3722 1.9719 11.2025 2.0887 11.169 2.26683L10.812 4.16248C10.7775 4.3404 10.892 4.51299 11.0675 4.54796C11.2431 4.58293 11.4134 4.46698 11.4479 4.28906C11.4481 4.28791 11.4483 4.28676 11.4485 4.28561L11.8055 2.38996C11.8391 2.21183 11.7238 2.0399 11.548 2.00588Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="SMenuEventIcon.tsx" />
				<path d="M8.97729 4.99537C8.97688 4.99512 8.97648 4.99487 8.97607 4.99463L7.34397 4.00057C7.19059 3.90715 6.99154 3.95747 6.89936 4.1129C6.80718 4.26833 6.85683 4.47006 7.01021 4.56347V4.56364L8.64235 5.55753C8.79552 5.65127 8.9947 5.60141 9.08716 5.44614C9.17962 5.29088 9.1305 5.08911 8.97729 4.99537Z" fill={props.fill ?? props.stroke ?? 'currentColor'} data-sentry-element="path" data-sentry-source-file="SMenuEventIcon.tsx" />
			</svg>
		</SvgIcon>;
};