import { Components, Theme } from '@mui/material'

export const buttonOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiButton: {
		styleOverrides: {
			root: {
				borderRadius: '30px',
				// width: '100%',
				// boxShadow: '0px 26px 60px 0px rgba(141, 155, 170, 0.05)',
			},
			contained: {
				padding: theme.spacing(1.5, 3),
				'&:disabled': {
					// opacity: 0.3,
					color: theme.palette.neutral.lightGrey,
					backgroundColor: theme.palette.neutral.darkGrey,
				},
			},
			outlined: {
				padding: theme.spacing(1.5, 3),
				// borderWidth: '2px',
				// borderColor: theme.palette.primary.dark,
				// color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.dark,
				// borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.dark,
				// width: 'fit-content',
				// '&:disabled': {
				// 	opacity: 0.6,
				// 	color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.dark,
				// 	borderColor:
				// 		theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.dark,
				// },
			},
			text: {
				padding: theme.spacing(1.5, 3),
				// color: theme.palette.primary.dark,
				// '&:disabled': {
				// 	opacity: 0.6,
				// 	color: theme.palette.primary.dark,
				// },
			},
			// startIcon: {
			// 	'&, & svg': {
			// 		width: theme.spacing(3),
			// 		height: theme.spacing(3),
			// 	},
			// },
			// endIcon: {
			// 	'&, & svg': {
			// 		width: theme.spacing(3),
			// 		height: theme.spacing(3),
			// 	},
			// },
			disabled: {},
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				// width: 'unset',
				// height: 'unset',
			},
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: {
				'&:disabled': {
					opacity: 0.3,
				},
			},
		},
	},
})
