/*
| Developed by Dirupt
| Filename : MBackRound.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MBackRoundProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MBackRound: React.FC<MBackRoundProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MBackRound" data-sentry-source-file="MBackRound.tsx">
			<svg viewBox="0 0 32 32" data-sentry-element="svg" data-sentry-source-file="MBackRound.tsx">
				<rect x="32" y="32" width="32" height="32" rx="16" transform="rotate(180 32 32)" fill="#DCE2E6" data-sentry-element="rect" data-sentry-source-file="MBackRound.tsx" />
				<path d="M22.0951 16.0002L9.90465 16.0002" stroke={props.fill ?? props.stroke ?? '#090938'} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MBackRound.tsx" />
				<path d="M14.2147 20.3102L9.90472 16.0002L14.2147 11.6903" stroke={props.fill ?? props.stroke ?? '#090938'} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MBackRound.tsx" />
			</svg>
		</SvgIcon>;
};