/*
| Developed by Dirupt
| Filename : urls.config.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { PartnerCategory } from '@/services/api/modules/partner/partner.contract'

export const UrlsConfig = {
	domain: process.env.NEXT_PUBLIC_HOST,
	feed: '/feed',
	// faq: '/faq',
	faq: 'https://www.senskle.com/faq/',
	directory: {
		users: '/directory/users',
		events: '/directory/events',
		partners: (categories: Array<PartnerCategory>) =>
			`/directory/partners?categories=${categories.join(',').toLowerCase()}`,
		partnerFilters: (categories: Array<PartnerCategory>) =>
			`/directory/partners/filters?categories=${categories.join(',')}`,
	},
	admin: {
		redeemCodes: '/admin/redeem-codes',
	},
	partner: {
		details: (id: string) => `/partner/${id}`,
		space: '/partner/space',
	},
	ambassador: {
		space: '/ambassador/space',
		community: '/ambassador/community',
	},
	event: {
		_managementActiveLink: '/event/management',
		list: '/event/management/list',
		details: (eventId: string) => `/event/${eventId}`,
		create: '/event/management/create',
		edit: (eventId: string) => `/event/management/${eventId}/edit`,
	},
	post: {
		create: '/post/create',
		details: (id: string) => `/post/${id}`,
	},
	rooms: {
		index: '/rooms',
		room: (roomId: string) => `/rooms/${roomId}`,
		request: (recipientUserId: string) => `/rooms/${recipientUserId}/request`,
		manageRequest: (applicantUserId: string) => `/rooms/${applicantUserId}/request/management`,
	},
	notifications: {
		index: '/notifications',
	},
	profile: {
		details: (id: string) => `/profile/${id}`, // Profile details
	},
	auth: {
		login: '/auth/login',
		createAccount: '/auth/create-account',
		forgotPassword: '/auth/forgot-password',
		verify: (email?: string) => (email ? `/auth/verify?email=${email}` : '/auth/verify'),
		betaRestricted: '/auth/beta-restricted',
	},
	account: {
		onboarding: '/account/onboarding',
		index: '/account', // Profile menu
		accountInformations: '/account/informations', // Edit account
		password: '/account/password', // Change password
		profile: '/account/profile', // Edit profile
		crushes: '/account/crushes', // Crushes
		mediaFolderRequests: '/account/media-folder-requests', // Media folder requests
		blacklist: '/account/blacklist', // Blacklist
		filters: '/account/filters', // Filters
		questions: '/account/questions', // Raise mask
		kyc: '/account/kyc', // Verify account
		account: '/account/account', // Account
		subscription: '/account/subscription', // Subscription
		rewards: '/account/rewards', // Rewards
		settings: '/account/settings', // Settings
		// becomePartner: '/profile/become-partner', // Become partner
		becomePartner: 'https://www.senskle.com/contact/', // Become partner
	},
	external: {
		website: 'https://www.senskle.com/',
		policy: 'https://www.senskle.com/politique-de-confidentialite/',
		cguCgv: 'https://www.senskle.com/cgu-cgv/',
		codeOfConduct: 'https://www.senskle.com/charte-valeurs/',
		privacyPolicy: 'https://www.senskle.com/politique-de-confidentialite/',
	},
}
