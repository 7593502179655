/*
| Developed by Dirupt
| Filename : DesktopUserItems.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, Grid, GridProps, styled, Tooltip } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { mutate } from 'swr';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav';
import { DesktopUserButton } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopUserButton';
import { DesktopNavContainer } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItem';
import { AuthEndpoint } from '@/services/api/modules/auth/auth.endpoint';
import { UrlsConfig } from '@/config/urls.config';
import { SLogout } from '@/components/common/svgs/icons/SLogout';
import { MDarkModeSwitch } from '@/components/common/MDarkModeSwitch';
import { IN_DEV } from '@/config/common.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopUserItemsProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const UserButtonsContainer = styled(Grid)<GridProps>(({
  theme
}) => ({
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  position: 'relative',
  flex: 1,
  '@media (min-width: 360px)': {
    gap: theme.spacing(4)
  },
  '@media (min-width: 430px)': {
    gap: theme.spacing(5)
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopUserItems: React.FC<DesktopUserItemsProps> = () => {
  const {
    t
  } = useTranslation();
  const router = useRouter();

  // Methods
  // ----------------------------------------------------------------------------
  const handleLogout = React.useCallback(() => {
    AuthEndpoint.logout().then(async () => {
      await router.replace(UrlsConfig.auth.login);
      await mutate(() => true, undefined, {
        revalidate: false
      });
    }).catch(console.error);
  }, [router]);

  // Render
  // ----------------------------------------------------------------------------
  return <UserButtonsContainer data-sentry-element="UserButtonsContainer" data-sentry-component="DesktopUserItems" data-sentry-source-file="DesktopUserItems.tsx">
			{IN_DEV ? <MDarkModeSwitch /> : null}
			<DesktopUserButton data-sentry-element="DesktopUserButton" data-sentry-source-file="DesktopUserItems.tsx" />
			<Tooltip title={t('common:desktop.userButtons.disconnect')} arrow data-sentry-element="Tooltip" data-sentry-source-file="DesktopUserItems.tsx">
				<Box data-sentry-element="Box" data-sentry-source-file="DesktopUserItems.tsx">
					<DesktopNavContainer onClick={handleLogout} data-sentry-element="DesktopNavContainer" data-sentry-source-file="DesktopUserItems.tsx">
						<SLogout data-sentry-element="SLogout" data-sentry-source-file="DesktopUserItems.tsx" />
					</DesktopNavContainer>
				</Box>
			</Tooltip>
		</UserButtonsContainer>;
};