/*
| Developed by Dirupt
| Filename : MobileNavButton.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, ClickAwayListener, Fab, FabProps, styled } from '@mui/material';
import { AnimatePresence, useCycle } from 'framer-motion';
import { MSearch } from '@/components/common/svgs/icons/MSearch';
import { MOBILE_MEDIUM_PHONE, MOBILE_NAV_HEIGHT, MOBILE_SMALL_PHONE, MOBILE_VERY_SMALL_PHONE } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNav';
import { MobileNavButtonItem, MobileNavButtonItemProps } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNavButtonItem';
import { MClose } from '@/components/common/svgs/icons/MClose';
import { UrlsConfig } from '@/config/urls.config';
import { PartnerCategory } from '@/services/api/modules/partner/partner.contract';
import { SMenuUserIcon, SMenuUserIconProps } from '@/components/common/svgs/icons/SMenuUserIcon';
import { SMenuPartnerIcon, SMenuPartnerIconProps } from '@/components/common/svgs/icons/SMenuPartnerIcon';
import { SMenuEventIcon, SMenuEventIconProps } from '@/components/common/svgs/icons/SMenuEventIcon';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MobileNavButtonProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SearchButton = styled(Fab)<FabProps>(() => ({
  position: 'absolute',
  bottom: MOBILE_NAV_HEIGHT / 2,
  left: '50%',
  transform: 'translateX(-50%)'
}));
const MenuUserIcon = styled(SMenuUserIcon)<SMenuUserIconProps>(() => ({
  fill: 'currentColor',
  '& path': {
    fill: 'currentColor'
  },
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));
const MenuPartnerIcon = styled(SMenuPartnerIcon)<SMenuPartnerIconProps>(() => ({
  fill: 'currentColor',
  '& path': {
    fill: 'currentColor'
  },
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));
const MenuEventIcon = styled(SMenuEventIcon)<SMenuEventIconProps>(() => ({
  fill: 'currentColor',
  '& path': {
    fill: 'currentColor'
  },
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MobileNavButton: React.FC<MobileNavButtonProps> = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  // Nav items
  // ----------------------------------------------------------------------------
  const navItems = React.useMemo<Array<Omit<MobileNavButtonItemProps, 'toggleOpen' | 'custom'>>>(() => {
    return [{
      icon: <MenuUserIcon />,
      label: 'Utilisateurs',
      href: UrlsConfig.directory.users
    }, {
      icon: <MenuPartnerIcon />,
      label: 'Partenaires',
      href: UrlsConfig.directory.partners([PartnerCategory.ALL])
    }, {
      icon: <MenuEventIcon />,
      label: 'Évènements',
      href: UrlsConfig.directory.events
    }];
  }, []);

  // Render
  // ----------------------------------------------------------------------------
  return <ClickAwayListener onClickAway={() => {
    if (isOpen) {
      toggleOpen();
    }
  }} data-sentry-element="ClickAwayListener" data-sentry-component="MobileNavButton" data-sentry-source-file="MobileNavButton.tsx">
			<Box data-sentry-element="Box" data-sentry-source-file="MobileNavButton.tsx">
				<AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="MobileNavButton.tsx">
					{isOpen ? navItems.map((button, index) => <MobileNavButtonItem key={index} custom={index} icon={button.icon} label={button.label} href={button.href} toggleOpen={toggleOpen} />) : null}
				</AnimatePresence>
				<SearchButton color={'primary'} aria-label={'toggle menu'} onClick={() => toggleOpen()} data-sentry-element="SearchButton" data-sentry-source-file="MobileNavButton.tsx">
					{isOpen ? <MClose /> : <MSearch />}
				</SearchButton>
			</Box>
		</ClickAwayListener>;
};