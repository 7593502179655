/*
| Developed by Dirupt
| Filename : casl.ability.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AbilityBuilder, PureAbility } from '@casl/ability'
import { User, UserRole } from '@/services/api/modules/user/user.contract'

/*
|--------------------------------------------------------------------------
| Contract
|--------------------------------------------------------------------------
*/
type Actions = 'manage' | 'access' | 'create' | 'read' | 'update' | 'delete' | 'quest'
export type Subjects = 'premiumContent' | 'adminOnly' | 'accountCompleted' | 'all'
export type AppAbility = PureAbility<[Actions, Subjects]>

/*
|--------------------------------------------------------------------------
| Ability
|--------------------------------------------------------------------------
*/
export function defineRulesFor(user: User): AppAbility {
	const { can, cannot, build } = new AbilityBuilder<AppAbility>(PureAbility)

	// Roles ability
	// ----------------------------------------------------------------------------
	switch (user.role) {
		case UserRole.USER:
			can('read', 'all')
			break
		case UserRole.PARTNER:
			can('read', 'all')
			break
		case UserRole.AMBASSADOR:
			can('read', 'all')
			break
		case UserRole.ADMIN:
			can('manage', 'all')
			break
	}

	// Subscription ability
	// ----------------------------------------------------------------------------
	if (user.isSubscriptionActive) {
		can('manage', 'premiumContent')
	} else {
		cannot('manage', 'premiumContent').because('You need to upgrade to a premium account to access this feature')
	}

	// if (user.isTrial || user.isSubscriptionActive) {
	// 	can('manage', 'premium-with-trial')
	// } else {
	// 	cannot('manage', 'premium-with-trial').because(
	// 		'You need to upgrade to a premium account to access this feature',
	// 	)
	// }

	// Complete profile ability
	// ----------------------------------------------------------------------------
	if (user?.userInformation?.completed) {
		can('manage', 'accountCompleted')
	} else {
		cannot('manage', 'accountCompleted').because('You need to complete your profile to access this feature')
	}

	// Admin ability
	// ----------------------------------------------------------------------------
	if (user.isAdmin) {
		can('manage', 'adminOnly')
	}

	// Build
	// ----------------------------------------------------------------------------
	return build()
}
