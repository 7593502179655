/*
| Developed by Dirupt
| Filename : UserMenuHeader.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { HTMLMotionProps, m } from 'framer-motion';
import { Avatar, Box, BoxProps, Button, styled } from '@mui/material';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { User } from '@/services/api/modules/user/user.contract';
import { variantFade } from '@/components/animate';
import { Link, RouterLink } from '@/components/common/MLink';
import { API_HOST } from '@/config/common.config';
import { UrlsConfig } from '@/config/urls.config';
import { UsernameWithBadge } from '@/components/common/UsernameWithBadge';
import { useCompleteAccountAbility } from '@/services/casl/hooks/useCompleteAccountAbility';
import { SNotice } from '@/components/common/SNotice';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UserMenuHeaderProps {
  authUser: User;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Header = styled(Box)<BoxProps & HTMLMotionProps<'header'>>(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  background: theme.palette.mode === 'dark' ? 'background: `linear-gradient(180deg, #020211 0%, #121223 100%)' : theme.palette.background.default
}));
const AvatarContainer = styled(Box)<BoxProps & NextLinkProps>(({
  theme
}) => ({
  width: theme.spacing(9.5),
  height: theme.spacing(9.5),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.gradients.pink,
  marginBottom: theme.spacing(1),
  borderRadius: '50%'
}));
const AvatarStyles = styled(Avatar)(({
  theme
}) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  borderStyle: 'solid',
  borderWidth: 3,
  borderColor: theme.palette.background.default
}));
const LinkSeeProfile = styled(Link)(({
  theme
}) => ({
  fontSize: 16,
  color: theme.palette.primary.main
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UserMenuHeader: React.FC<UserMenuHeaderProps> = ({
  authUser
}) => {
  const {
    t
  } = useTranslation();
  const completeAccountAbility = useCompleteAccountAbility();
  const {
    pathname
  } = useRouter();
  const isAccountHome = React.useMemo(() => pathname.endsWith(UrlsConfig.account.index), [pathname]);

  // Render
  // ----------------------------------------------------------------------------
  if (!authUser?.id) return null;
  return <Header component={m.header} variants={variantFade().inDown} data-sentry-element="Header" data-sentry-component="UserMenuHeader" data-sentry-source-file="UserMenuHeader.tsx">
			<AvatarContainer component={RouterLink} href={UrlsConfig.profile.details(authUser.id)} data-sentry-element="AvatarContainer" data-sentry-source-file="UserMenuHeader.tsx">
				{authUser?.userInformation?.avatarId ? <AvatarStyles src={`${API_HOST}${authUser.userInformation.avatarUrl}`} /> : <AvatarStyles src={'/assets/images/logo/senskle-default.png'} />}
			</AvatarContainer>
			<UsernameWithBadge user={authUser} typographyProps={{
      variant: 'subtitle1'
    }} data-sentry-element="UsernameWithBadge" data-sentry-source-file="UserMenuHeader.tsx" />
			{!completeAccountAbility && !isAccountHome ? <SNotice mode="vertical" title={t('common:needCompleteAccountNotice.dense.title')} description={t('common:needCompleteAccountNotice.dense.description')} actions={<Button variant="contained" color="primary" component={Link} href={UrlsConfig.account.index}>
							{t('common:needCompleteAccountNotice.dense.button')}
						</Button>} cardProps={{
      sx: {
        marginX: 4,
        marginY: 2,
        padding: 2
      }
    }} /> : null}
			<LinkSeeProfile href={UrlsConfig.profile.details(authUser.id)} variant={'subtitle1'} data-sentry-element="LinkSeeProfile" data-sentry-source-file="UserMenuHeader.tsx">
				{t('account/index:seeProfile')}
			</LinkSeeProfile>
		</Header>;
};