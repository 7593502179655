/*
| Developed by Dirupt
| Filename : MobileNavButtonItem.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Fab, FabProps, styled } from '@mui/material';
import { Cycle, m } from 'framer-motion';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNav';
import { RouterLink } from '@/components/common/MLink';
import { useActiveLink } from '@/hooks/useActiveLink';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MobileNavButtonItemProps {
  icon: React.ReactNode;
  label: string;
  href: string;
  toggleOpen: Cycle;
  custom: number;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const FloatingButton = styled(Fab, {
  shouldForwardProp: propName => propName !== 'active'
})<FabProps & {
  active: boolean;
}>(({
  theme,
  active
}) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.text.primary,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
  color: theme.palette.text.primary,
  boxShadow: '0px 16px 32px rgba(33, 33, 33, 0.20)',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.white
  },
  ...(active && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.white
  })
}));

/*
|--------------------------------------------------------------------------
| Variants
|--------------------------------------------------------------------------
*/
const buttonVariants = {
  open: (index: number) => {
    const radius = 90;
    const interval = Math.PI / (3 + 1);
    const angle = Math.PI - interval * (index + 1);
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * -radius;
    return {
      x: x,
      y: y,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: 0.075 * index
      }
    };
  },
  closed: (index: number) => {
    return {
      x: 0,
      y: 0,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: 0.075 * index
      }
    };
  }
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MobileNavButtonItem: React.FC<MobileNavButtonItemProps> = props => {
  const active = useActiveLink(props.href);

  // render
  // ----------------------------------------------------------------------------
  return <m.div key={props.label} variants={buttonVariants} custom={props.custom} initial="closed" animate="open" exit="closed" style={{
    position: 'absolute',
    bottom: MOBILE_NAV_HEIGHT / 2 - 16,
    left: 'calc(50% - 28px)',
    zIndex: 1
  }} data-sentry-element="unknown" data-sentry-component="MobileNavButtonItem" data-sentry-source-file="MobileNavButtonItem.tsx">
			<FloatingButton color={'secondary'} aria-label={props.label} component={RouterLink} href={props.href} onClick={() => props.toggleOpen()} active={active} data-sentry-element="FloatingButton" data-sentry-source-file="MobileNavButtonItem.tsx">
				{props.icon}
			</FloatingButton>
		</m.div>;
};