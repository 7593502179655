/*
| Developed by Dirupt
| Filename : BadgePremium.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface BadgePremiumProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const BadgePremium: React.FC<BadgePremiumProps> = React.forwardRef((props, ref) => {
  return <SvgIcon {...props} inheritViewBox ref={ref}>
			<svg viewBox="0 0 64 64">
				<path fillRule="evenodd" clipRule="evenodd" d="M32.45 15.9803C29.24 15.9803 26.11 16.9168 23.45 18.6716C20.79 20.4264 18.71 22.9205 17.48 25.8386C16.25 28.7566 15.93 31.9704 16.56 35.0659C17.19 38.1614 18.73 41.0105 21 43.2483C23.27 45.4861 26.15 47.0043 29.3 47.6254C32.44 48.2465 35.7 47.9211 38.66 46.7184C41.62 45.5059 44.15 43.4652 45.93 40.833C47.71 38.2107 48.66 35.1152 48.66 31.9606C48.66 27.7215 46.95 23.6599 43.91 20.663C40.87 17.6661 36.75 15.9803 32.45 15.9803ZM39.86 30.5804L32.63 37.708C32.19 38.1319 31.61 38.3685 31 38.3685C30.39 38.3685 29.8 38.1319 29.37 37.708L25.01 33.4295C24.58 32.9957 24.34 32.4141 24.34 31.8127C24.34 31.2113 24.58 30.6297 25.01 30.1959C25.22 29.9791 25.48 29.8115 25.76 29.6932C26.04 29.5749 26.34 29.5157 26.65 29.5157C26.96 29.5157 27.26 29.5749 27.54 29.6932C27.82 29.8115 28.08 29.9791 28.29 30.1959L30.98 32.8675L36.59 27.337C37.03 26.9131 37.61 26.6765 38.23 26.6765C38.84 26.6765 39.43 26.923 39.86 27.3469C40.29 27.7807 40.53 28.3524 40.53 28.9636C40.53 29.565 40.28 30.1466 39.85 30.5706L39.86 30.5804Z" fill="#A8D827" />
				<path fillRule="evenodd" clipRule="evenodd" d="M58.31 21.9643C57.99 21.4911 57.77 20.9489 57.66 20.3869L55.86 11.6426C55.68 10.7653 55.23 9.95687 54.59 9.32594C53.95 8.69501 53.13 8.26124 52.24 8.0838L43.55 6.3093C42.97 6.191 42.42 5.96426 41.93 5.62908L34.56 0.778805C33.8 0.266174 32.9 0 31.99 0C31.08 0 30.18 0.276032 29.42 0.778805L22.05 5.62908C21.56 5.96426 21 6.191 20.41 6.3093L11.74 8.0838C10.85 8.2711 10.04 8.70487 9.4 9.34566C8.76 9.97659 8.33 10.785 8.15 11.6624L6.35 20.4067C6.21 20.9587 5.98 21.4911 5.66 21.9643L0.74 29.4566C0.25 30.2058 0 31.0635 0 31.9507C0 32.838 0.26 33.7055 0.74 34.4449L5.64 41.9372C5.95 42.4202 6.17 42.9526 6.29 43.5145L8.09 52.2588C8.26 53.1559 8.69 53.984 9.34 54.6346C9.99 55.2853 10.81 55.7289 11.72 55.9162L20.39 57.6907C20.98 57.7991 21.54 58.0357 22.03 58.3709L29.4 63.2212C30.16 63.7338 31.06 64 31.97 64C32.88 64 33.78 63.724 34.54 63.2212L41.91 58.3709C42.4 58.0357 42.95 57.809 43.53 57.6907L52.22 55.9162C53.11 55.7289 53.92 55.2951 54.56 54.6543C55.2 54.0234 55.63 53.215 55.81 52.3377L57.61 43.5933C57.72 43.0019 57.95 42.4301 58.3 41.9372L63.24 34.4449C63.72 33.7055 63.98 32.8478 63.98 31.9704C63.98 31.093 63.72 30.2354 63.24 29.496L58.3 21.9643H58.31ZM32.44 52.4757C28.32 52.4757 24.3 51.273 20.87 49.0154C17.45 46.7579 14.78 43.5539 13.2 39.7979C11.62 36.0419 11.21 31.9211 12.02 27.9384C12.82 23.9556 14.81 20.2982 17.72 17.4295C20.63 14.5607 24.34 12.5989 28.38 11.8102C32.42 11.0216 36.61 11.4258 40.41 12.9735C44.22 14.5311 47.47 17.1633 49.76 20.5348C52.05 23.9063 53.27 27.8792 53.27 31.9409C53.26 37.3826 51.06 42.5977 47.16 46.4424C43.26 50.2871 37.97 52.456 32.45 52.4658L32.44 52.4757Z" fill="#FF416D" />
			</svg>
		</SvgIcon>;
});
BadgePremium.displayName = 'BadgePremium';