/*
| Developed by Dirupt
| Filename : SPageDefaultDesktopHeader.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { SPageDefaultProps } from '@/components/layouts/pages/SPageDefault/SPageDefault';
import { MPaddedBox } from '@/components/common/MPaddedBox';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';
import { MotionContainer } from '@/components/animate';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SPageDefaultDesktopHeaderProps extends Pick<SPageDefaultProps, 'title' | 'subTitle' | 'breadcrumbs' | 'headerProps' | 'headerActions'> {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const HeaderStyles = styled(Box)<BoxProps>(({
  theme
}) => ({
  display: 'none',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1)
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SPageDefaultDesktopHeader: React.FC<SPageDefaultDesktopHeaderProps> = ({
  title,
  subTitle,
  headerProps,
  headerActions
  // breadcrumbs,
}) => {
  // const { t } = useTranslation()

  return <MPaddedBox paddingBottom={0} component={MotionContainer} data-sentry-element="MPaddedBox" data-sentry-component="SPageDefaultDesktopHeader" data-sentry-source-file="SPageDefaultDesktopHeader.tsx">
			<HeaderStyles {...headerProps} data-sentry-element="HeaderStyles" data-sentry-source-file="SPageDefaultDesktopHeader.tsx">
				{/*<Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>*/}
				{/*	<Link href={UrlsConfig.feed}>*/}
				{/*		<Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>*/}
				{/*			<MHome sx={{ fontSize: 14 }} />*/}
				{/*			<Typography variant={'caption'}>{t('common:urlsLabel.feed')}</Typography>*/}
				{/*		</Box>*/}
				{/*	</Link>*/}
				{/*	{breadcrumbs?.length && breadcrumbs.length > 0 ? (*/}
				{/*		<Typography variant={'caption'}>/</Typography>*/}
				{/*	) : null}*/}
				{/*	{breadcrumbs?.map((breadcrumb, index) => (*/}
				{/*		<React.Fragment key={index}>*/}
				{/*			<Link href={breadcrumb.url}>*/}
				{/*				<Typography variant={'caption'}>{breadcrumb.label}</Typography>*/}
				{/*			</Link>*/}
				{/*			{index < breadcrumbs.length - 1 ? <Typography variant={'caption'}>/</Typography> : null}*/}
				{/*		</React.Fragment>*/}
				{/*	))}*/}
				{/*</Box>*/}
				<Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} width={'fit-content'} data-sentry-element="Box" data-sentry-source-file="SPageDefaultDesktopHeader.tsx">
					{title ? <Typography variant={'h2'}>{title}</Typography> : null}
					{subTitle ? <Typography variant={'subtitle1'}>{subTitle}</Typography> : null}
				</Box>
				{headerActions ? headerActions : null}
			</HeaderStyles>
		</MPaddedBox>;
};