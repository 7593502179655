/*
| Developed by Dirupt
| Filename : DefaultLayout.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, styled } from '@mui/material';
import { PureAbility } from '@casl/ability';
import { setUser } from '@sentry/nextjs';
import { MOBILE_NAV_HEIGHT, MobileNav } from '@/components/layouts/app/DefaultLayout/MobileNav';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { AppAbility, defineRulesFor } from '@/services/casl/casl.ability';
import { AbilityContext } from '@/services/casl/casl.context';
import { useResponsive } from '@/hooks/useResponsive';
import { DESKTOP_NAV_HEIGHT, DesktopNav } from '@/components/layouts/app/DefaultLayout/DesktopNav';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DefaultLayoutProps {
  children: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DefaultLayoutContainer = styled(Box)(({
  theme
}) => ({
  minHeight: '100vh',
  position: 'relative',
  paddingBottom: `${MOBILE_NAV_HEIGHT + 24}px`,
  display: 'flex',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    paddingBottom: 0,
    paddingTop: `${DESKTOP_NAV_HEIGHT}px`
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children
}) => {
  const {
    data: user
  } = useMe();
  const [ability, setAbility] = React.useState<AppAbility>(new PureAbility());
  const isDesktopOnly = useResponsive('up', 'md');

  // React.useEffect(() => {
  // 	const usersOnlineSSE = new SseMercureService(`/users/status`, {
  // 		withCredentials: true,
  // 	})
  //
  // 	if (user?.id) {
  // 		usersOnlineSSE.connect()
  //
  // 		if (usersOnlineSSE.eventSource) {
  // 			usersOnlineSSE.eventSource.onmessage = (event) => {
  // 				console.log('onmessage', event)
  // 			}
  // 		}
  // 	}
  //
  // 	return () => {
  // 		if (usersOnlineSSE.eventSource) {
  // 			usersOnlineSSE.eventSource.close()
  // 		}
  // 	}
  // }, [user?.id])

  // Identify user on Sentry
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    if (user) {
      const {
        id,
        email,
        userInformation
      } = user;
      setUser({
        id: id ?? undefined,
        email: email ?? undefined,
        username: userInformation?.pseudo ?? undefined
      });
    } else {
      setUser(null);
    }
  }, [user]);

  // Update ability with user
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    if (user) {
      const rules = defineRulesFor(user);
      setAbility(rules);
    }
  }, [user]);

  // Render
  // ----------------------------------------------------------------------------
  return <AbilityContext.Provider value={ability} data-sentry-element="unknown" data-sentry-component="DefaultLayout" data-sentry-source-file="DefaultLayout.tsx">
			<DefaultLayoutContainer data-sentry-element="DefaultLayoutContainer" data-sentry-source-file="DefaultLayout.tsx">
				{isDesktopOnly ? <DesktopNav /> : null}
				{children}
				{!isDesktopOnly ? <MobileNav /> : null}
			</DefaultLayoutContainer>
		</AbilityContext.Provider>;
};