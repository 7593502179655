/*
| Developed by Dirupt
| Filename : auth.endpoint.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AxiosRequestConfig } from 'axios'
import { setUser } from '@sentry/nextjs'
import { axiosInstance } from '@/services/api/axios-instance'
import { AuthForgotPasswordDto, AuthLoginDto, AuthResetPasswordDto } from '@/services/api/modules/auth/auth.dto'
import { User } from '@/services/api/modules/user/user.contract'

/*
|--------------------------------------------------------------------------
| Auth URLs
|--------------------------------------------------------------------------
*/
export const authEndpointUrls = {
	// Common
	login: () => '/auth/login',
	logout: () => '/auth/logout',
	forgotPassword: () => '/auth/forgot-password',
	resetPassword: () => '/auth/reset-password',
	// Admin
	loginAsUser: (userId: string) => `/admin/auth/login-as-user/${userId}`,
} as const

/*
|--------------------------------------------------------------------------
| Auth endpoint
|--------------------------------------------------------------------------
*/
export class AuthEndpoint {
	// [POST] - /auth/login - (GUEST)
	// ----------------------------------------------------------------------------
	public static login(data: AuthLoginDto, options?: AxiosRequestConfig) {
		return axiosInstance<null>({ ...options, url: authEndpointUrls.login(), method: 'POST', data })
	}

	// [POST] - /auth/forgot-password - (GUEST)
	// ----------------------------------------------------------------------------
	public static forgotPassword(data: AuthForgotPasswordDto, options?: AxiosRequestConfig) {
		return axiosInstance<null>({ ...options, url: authEndpointUrls.forgotPassword(), method: 'POST', data })
	}

	// [POST] - /auth/reset-password - (GUEST)
	// ----------------------------------------------------------------------------
	public static resetPassword(data: AuthResetPasswordDto, options?: AxiosRequestConfig) {
		return axiosInstance<User>({
			...options,
			url: authEndpointUrls.resetPassword(),
			method: 'POST',
			data,
		})
	}

	// [GET] - /auth/logout - (AUTH)
	// ----------------------------------------------------------------------------
	public static async logout(options?: AxiosRequestConfig) {
		await axiosInstance<null>({ ...options, url: authEndpointUrls.logout(), method: 'GET' })
		setUser(null)
	}

	// [POST] - /admin/auth/login-as-user/:userId - (AUTH)
	// ----------------------------------------------------------------------------
	public static loginAsUser(userId: string, options?: AxiosRequestConfig) {
		return axiosInstance<User>({ ...options, url: authEndpointUrls.loginAsUser(userId), method: 'POST' })
	}
}
