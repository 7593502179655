/*
| Developed by Dirupt
| Filename : SettingsLayout.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, Card, Grid, styled } from '@mui/material';
import { PureAbility } from '@casl/ability';
import { setUser } from '@sentry/nextjs';
import { MOBILE_NAV_HEIGHT, MobileNav } from '@/components/layouts/app/DefaultLayout/MobileNav';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { AppAbility, defineRulesFor } from '@/services/casl/casl.ability';
import { AbilityContext } from '@/services/casl/casl.context';
import { useResponsive } from '@/hooks/useResponsive';
import { DESKTOP_NAV_HEIGHT, DesktopNav } from '@/components/layouts/app/DefaultLayout/DesktopNav';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';
import { UserMenuHeader } from '@/components/modules/user/menu';
import { UserNavItems } from '@/components/modules/user/menu/nav-items';
import { MotionContainer } from '@/components/animate';
import { SPageDefault } from '@/components/layouts/pages/SPageDefault';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsLayoutProps {
  children: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SettingsLayoutContainer = styled(Box)(({
  theme
}) => ({
  minHeight: '100vh',
  position: 'relative',
  paddingBottom: `${MOBILE_NAV_HEIGHT + 24}px`,
  display: 'flex',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    paddingBottom: 0,
    paddingTop: `${DESKTOP_NAV_HEIGHT}px`
  },
  '& .grid-content .page-default-layout': {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsLayout: React.FC<SettingsLayoutProps> = ({
  children
}) => {
  const {
    data: user
  } = useMe();
  const [ability, setAbility] = React.useState<AppAbility>(new PureAbility());
  const isDesktopOnly = useResponsive('up', 'md');
  const Container = isDesktopOnly ? Card : Box;

  // React.useEffect(() => {
  // 	const usersOnlineSSE = new SseMercureService(`/users/status`, {
  // 		withCredentials: true,
  // 	})
  //
  // 	if (user?.id) {
  // 		usersOnlineSSE.connect()
  //
  // 		if (usersOnlineSSE.eventSource) {
  // 			usersOnlineSSE.eventSource.onmessage = (event) => {
  // 				console.log('onmessage', event)
  // 			}
  // 		}
  // 	}
  //
  // 	return () => {
  // 		if (usersOnlineSSE.eventSource) {
  // 			usersOnlineSSE.eventSource.close()
  // 		}
  // 	}
  // }, [user?.id])

  // Identify user on Sentry
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    if (user) {
      const {
        id,
        email,
        userInformation
      } = user;
      setUser({
        id: id ?? undefined,
        email: email ?? undefined,
        username: userInformation?.pseudo ?? undefined
      });
    } else {
      setUser(null);
    }
  }, [user]);

  // Update ability with user
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    if (user) {
      const rules = defineRulesFor(user);
      setAbility(rules);
    }
  }, [user]);

  // Render
  // ----------------------------------------------------------------------------
  return <AbilityContext.Provider value={ability} data-sentry-element="unknown" data-sentry-component="SettingsLayout" data-sentry-source-file="SettingsLayout.tsx">
			<SettingsLayoutContainer className={'settings-layout'} data-sentry-element="SettingsLayoutContainer" data-sentry-source-file="SettingsLayout.tsx">
				{isDesktopOnly ? <DesktopNav /> : null}
				<SPageDefault disabledPadding component={MotionContainer} data-sentry-element="SPageDefault" data-sentry-source-file="SettingsLayout.tsx">
					<Grid container spacing={4} alignItems={'flex-start'} data-sentry-element="Grid" data-sentry-source-file="SettingsLayout.tsx">
						<Grid item xs={12} md={4} display={{
            xs: 'none',
            [DESKTOP_BREAKPOINT]: 'flex'
          }} data-sentry-element="Grid" data-sentry-source-file="SettingsLayout.tsx">
							{/* @ts-ignore */}
							<Container sx={{
              width: '100%'
            }} data-sentry-element="Container" data-sentry-source-file="SettingsLayout.tsx">
								{user?.id ? <UserMenuHeader authUser={user} /> : null}
								<UserNavItems data-sentry-element="UserNavItems" data-sentry-source-file="SettingsLayout.tsx" />
							</Container>
						</Grid>
						<Grid item xs={12} md={8} className={'grid-content'} data-sentry-element="Grid" data-sentry-source-file="SettingsLayout.tsx">
							{/* @ts-ignore */}
							<Container sx={{
              padding: {
                xs: 0,
                [DESKTOP_BREAKPOINT]: 2
              }
            }} data-sentry-element="Container" data-sentry-source-file="SettingsLayout.tsx">{children}</Container>
						</Grid>
					</Grid>
				</SPageDefault>
				{!isDesktopOnly ? <MobileNav /> : null}
			</SettingsLayoutContainer>
		</AbilityContext.Provider>;
};