import { Components, Theme } from '@mui/material'

export const chipOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiChip: {
		styleOverrides: {
			root: {
				height: theme.spacing(3),
				// backgroundColor: theme.palette.primary.main,
			},
			colorSecondary: {
				// backgroundColor: theme.palette.secondary.main,
				border: '1px solid',
				// borderColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : 'transparent',
				// color: theme.palette.mode === 'light' ? theme.palette.secondary.dark : theme.palette.text.primary,
			},
			avatarColorPrimary: {
				border: '1px solid black',
			},
			deleteIcon: {
				fill: theme.palette.text.primary,
				width: 16,
				height: 16,
			},
		},
	},
})
