/*
| Developed by Dirupt
| Filename : useMe.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import useSWR, { SWRConfiguration } from 'swr';
import { UserEndpoint, userEndpointUrls } from '@/services/api/modules/user/user.endpoint';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useMe = (swrOptions?: SWRConfiguration) => {
  return useSWR(userEndpointUrls.getMe(), () => UserEndpoint.getMe().then(res => res.data), swrOptions);
};