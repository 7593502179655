/*
| Developed by Dirupt
| Filename : MotionLazy.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { LazyMotion, m, domMax } from 'framer-motion';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MotionLazyProps {
  children: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MotionLazy: React.FC<MotionLazyProps> = ({
  children
}) => {
  return <LazyMotion strict features={domMax} data-sentry-element="LazyMotion" data-sentry-component="MotionLazy" data-sentry-source-file="MotionLazy.tsx">
			<m.div style={{
      height: '100%'
    }} data-sentry-element="unknown" data-sentry-source-file="MotionLazy.tsx"> {children} </m.div>
		</LazyMotion>;
};