/*
| Developed by Dirupt
| Filename : AuthLayout.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { AnimatePresence, m, MotionProps } from 'framer-motion';
import { Senskle } from '@/components/common/svgs/logo/Senskle';
import AuthPicture from '@/assets/images/auth/auth-picutre.png';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';
import { MotionContainer, variantFade } from '@/components/animate';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type AuthLayoutProps = React.PropsWithChildren;

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Container = styled(Box)<BoxProps>(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(6),
  padding: theme.spacing(2),
  minHeight: 'calc(100vh - 61px)',
  // maxHeight: 'calc(100vh - 61px)',
  // overflow: 'hidden',

  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    gap: theme.spacing(2)
  }
}));
const ContentContainer = styled(Box)<BoxProps & MotionProps>(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    flex: 1
  }
}));
const FormContainer = styled(Box)(({
  theme
}) => ({
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(6),
    boxShadow: '0px 8px 16px rgba(33, 33, 33, 0.20)',
    maxWidth: 500,
    width: '100%'
  }
}));
const PictureContainer = styled(Box)<BoxProps>(({
  theme
}) => ({
  display: 'none',
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8),
    backgroundImage: `linear-gradient(118deg, rgba(13, 1, 89, 0.60) 3.46%, rgba(165, 0, 123, 0.60) 50.17%, rgba(245, 31, 86, 0.60) 96.89%), url(${AuthPicture.src})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderRadius: theme.spacing(2),
    minHeight: 500,
    maxWidth: 550 + 2 * 8,
    marginLeft: `-${2 * 8}px`,
    color: theme.palette.neutral.white,
    textAlign: 'center'
  }
}));
const RotationTitleContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 65
}));

/*
|--------------------------------------------------------------------------
| Configurations
|--------------------------------------------------------------------------
*/
const ROTATION_INTERVAL = 3000 as const; // 3 seconds

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export function AuthLayout({
  children
}: AuthLayoutProps) {
  const {
    t
  } = useTranslation();
  const titles = t('auth/common:picturePanel.titles', undefined, {
    returnObjects: true
  });
  const [currentTitle, setCurrentTitle] = React.useState<string>(titles[0]);

  // Get title rotation with titles (it's an array of string, like ['title1', 'title2', 'title3'])
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    const interval = setInterval(() => {
      const index = titles.indexOf(currentTitle);
      const nextIndex = index + 1 >= titles.length ? 0 : index + 1;
      setCurrentTitle(titles[nextIndex]);
    }, ROTATION_INTERVAL);
    return () => clearInterval(interval);
  }, [currentTitle, titles]);

  // Render
  // ----------------------------------------------------------------------------
  return <Container component={MotionContainer} data-sentry-element="Container" data-sentry-component="AuthLayout" data-sentry-source-file="AuthLayout.tsx">
			<Box textAlign={'center'} component={m.div} variants={variantFade().inDown} data-sentry-element="Box" data-sentry-source-file="AuthLayout.tsx">
				<Senskle sx={{
        width: '100%',
        height: 'fit-content',
        maxWidth: '148px',
        margin: '0 auto'
      }} data-sentry-element="Senskle" data-sentry-source-file="AuthLayout.tsx" />
			</Box>
			<ContentContainer component={m.div} variants={variantFade().inUp} data-sentry-element="ContentContainer" data-sentry-source-file="AuthLayout.tsx">
				<FormContainer data-sentry-element="FormContainer" data-sentry-source-file="AuthLayout.tsx">{children}</FormContainer>
				<PictureContainer data-sentry-element="PictureContainer" data-sentry-source-file="AuthLayout.tsx">
					<RotationTitleContainer data-sentry-element="RotationTitleContainer" data-sentry-source-file="AuthLayout.tsx">
						<AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="AuthLayout.tsx">
							<Typography key={currentTitle} component={m.p} initial={{
              y: -20,
              opacity: 0
            }} animate={{
              y: 0,
              opacity: 1
            }} exit={{
              y: 20,
              opacity: 0
            }} transition={{
              ease: 'easeInOut'
            }} sx={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              fontSize: 40
            }} variant={'h1'} data-sentry-element="Typography" data-sentry-source-file="AuthLayout.tsx">
								{currentTitle}
							</Typography>
						</AnimatePresence>
					</RotationTitleContainer>
					<Typography variant={'h3'} maxWidth={500} data-sentry-element="Typography" data-sentry-source-file="AuthLayout.tsx">
						{t('auth/common:picturePanel.subTitle')}
					</Typography>
				</PictureContainer>
			</ContentContainer>
		</Container>;
}