/*
| Developed by Dirupt
| Filename : DesktopNav.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, Container, ContainerProps, styled } from '@mui/material';
import Link from 'next/link';
import { DesktopBackgroundHeader } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopBackgroundHeader';
import { Senskle } from '@/components/common/svgs/logo/Senskle';
import { DesktopNavItems } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItems';
import { DesktopUserItems } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopUserItems';
import { UrlsConfig } from '@/config/urls.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopNavProps {
  unauthenticated?: boolean;
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const DESKTOP_NAV_HEIGHT = 140;

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MobileNav = styled(props => <Box component={'nav'} {...props} />)<BoxProps>(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 100
}));
const MobileNavContainer = styled(props => <Container maxWidth={'xl'} {...props} />)<ContainerProps>(() => ({
  position: 'absolute',
  bottom: -25,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));
const MobileToolBar = styled(Box, {
  shouldForwardProp: prop => prop !== 'unauthenticated'
})<BoxProps & Pick<DesktopNavProps, 'unauthenticated'>>(({
  theme,
  unauthenticated
}) => ({
  background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.neutral.white,
  borderRadius: 999,
  padding: theme.spacing(1, 4),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: unauthenticated ? 'center' : 'space-between',
  boxShadow: '0px 16px 32px 0px rgba(33, 33, 33, 0.20)',
  width: '100%'
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopNav: React.FC<DesktopNavProps> = ({
  unauthenticated = false
}) => {
  return <MobileNav data-sentry-element="MobileNav" data-sentry-component="DesktopNav" data-sentry-source-file="DesktopNav.tsx">
			<DesktopBackgroundHeader data-sentry-element="DesktopBackgroundHeader" data-sentry-source-file="DesktopNav.tsx" />
			<MobileNavContainer data-sentry-element="MobileNavContainer" data-sentry-source-file="DesktopNav.tsx">
				<MobileToolBar unauthenticated={unauthenticated} data-sentry-element="MobileToolBar" data-sentry-source-file="DesktopNav.tsx">
					{!unauthenticated ? <DesktopNavItems /> : null}
					<Link href={UrlsConfig.feed} data-sentry-element="Link" data-sentry-source-file="DesktopNav.tsx">
						<Senskle sx={{
            height: 'fit-content',
            width: '80px',
            margin: '0 auto'
          }} data-sentry-element="Senskle" data-sentry-source-file="DesktopNav.tsx" />
					</Link>
					{!unauthenticated ? <DesktopUserItems /> : null}
				</MobileToolBar>
			</MobileNavContainer>
		</MobileNav>;
};