/*
| Developed by Dirupt
| Filename : SPageDefaultMobileHeader.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { HTMLMotionProps, m } from 'framer-motion';
import { Box, BoxProps, IconButton, IconButtonProps, styled, Typography } from '@mui/material';
import { MotionContainer, variantSlide } from '@/components/animate';
import { MBackRound } from '@/components/common/svgs/icons/MBackRound';
import { UrlsConfig } from '@/config/urls.config';
import { Senskle } from '@/components/common/svgs/logo/Senskle';
import { DEFAULT_CONTENT_PADDING, MPaddedBox } from '@/components/common/MPaddedBox';
import { Link } from '@/components/common/MLink';
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config';
import { SPageDefaultProps } from '@/components/layouts/pages/SPageDefault/SPageDefault';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SPageDefaultMobileHeaderProps extends Pick<SPageDefaultProps, 'disabledGutters' | 'handleBack' | 'title' | 'subTitle' | 'headerProps' | 'headerActions'> {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const HeaderStyles = styled(Box, {
  shouldForwardProp: prop => prop !== 'disabledGutters'
})<BoxProps & Pick<SPageDefaultProps, 'disabledGutters'>>(({
  theme,
  disabledGutters
}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  position: 'relative',
  ...(disabledGutters ? {} : {
    paddingTop: theme.spacing(DEFAULT_CONTENT_PADDING)
  }),
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    display: 'none'
  }
}));
const HeaderBackButton = styled(IconButton)<IconButtonProps & HTMLMotionProps<'button'>>(() => ({
  position: 'absolute',
  top: '25%',
  left: 0,
  transform: 'translateY(-50%)'
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SPageDefaultMobileHeader: React.FC<SPageDefaultMobileHeaderProps> = ({
  disabledGutters,
  title,
  subTitle,
  handleBack,
  headerProps,
  headerActions
}) => {
  return <MPaddedBox paddingBottom={0} component={MotionContainer} data-sentry-element="MPaddedBox" data-sentry-component="SPageDefaultMobileHeader" data-sentry-source-file="SPageDefaultMobileHeader.tsx">
			<HeaderStyles {...headerProps} disabledGutters={disabledGutters} data-sentry-element="HeaderStyles" data-sentry-source-file="SPageDefaultMobileHeader.tsx">
				{handleBack ? <HeaderBackButton onClick={handleBack} component={m.button} variants={variantSlide().inLeft}>
						<MBackRound sx={{
          fontSize: 32
        }} />
					</HeaderBackButton> : null}
				<Link href={UrlsConfig.feed} data-sentry-element="Link" data-sentry-source-file="SPageDefaultMobileHeader.tsx">
					<Senskle sx={{
          fontSize: 46
        }} data-sentry-element="Senskle" data-sentry-source-file="SPageDefaultMobileHeader.tsx" />
				</Link>
				<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'center'} data-sentry-element="Box" data-sentry-source-file="SPageDefaultMobileHeader.tsx">
					{title ? <Typography variant={'subtitle1'}>{title}</Typography> : null}
					{subTitle ? <Typography variant={'body2'}>{subTitle}</Typography> : null}
				</Box>
				{headerActions ? headerActions : null}
			</HeaderStyles>
		</MPaddedBox>;
};