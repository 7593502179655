/*
| Developed by Dirupt
| Filename : MPaddedBox.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type MPaddedBoxProps = BoxProps & {
  children: React.ReactNode;
};

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const DEFAULT_CONTENT_PADDING = 3;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MPaddedBox: React.FC<MPaddedBoxProps> = ({
  children,
  ...props
}) => {
  return <Box padding={DEFAULT_CONTENT_PADDING} {...props} data-sentry-element="Box" data-sentry-component="MPaddedBox" data-sentry-source-file="MPaddedBox.tsx">
			{children}
		</Box>;
};