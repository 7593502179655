/*
| Developed by Dirupt
| Filename : rooms.endpoint.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from '@/services/api/axios-instance'
import { Room, RoomMessage, RoomWithExtra } from '@/services/api/modules/room/room.contract'
import { RoomCreateDto, RoomMessageCreateDto, RoomMessageUploadDto } from '@/services/api/modules/room/room.dto'

/*
|--------------------------------------------------------------------------
| Room URLs
|--------------------------------------------------------------------------
*/
export const roomEndpointUrls = {
	getRooms: () => '/user/room',
	getUnread: () => '/user/room/unread',
	getRoom: (roomId: string) => `/user/room/${roomId}`,
	setLastMessageRead: (roomId: string) => `/user/room/${roomId}/last-message-read`,
	createRoom: () => '/user/room',
	getMessages: (roomId: string) => `/user/room/${roomId}/message`,
	createMessage: (roomId: string) => `/user/room/${roomId}/message`,
	uploadImage: (roomId: string) => `/user/room/${roomId}/message/upload`,
}

/*
|--------------------------------------------------------------------------
| Room endpoint
|--------------------------------------------------------------------------
*/
export class RoomEndpoint {
	// [GET] - /user/rooms - (AUTH)
	// ----------------------------------------------------------------------------
	public static getRooms(options?: AxiosRequestConfig) {
		return axiosInstance<Array<Room>>({ ...options, url: roomEndpointUrls.getRooms(), method: 'GET' })
	}

	// [GET] - /user/rooms/unread - (AUTH)
	// ----------------------------------------------------------------------------
	public static getUnread(options?: AxiosRequestConfig) {
		return axiosInstance<number>({ ...options, url: roomEndpointUrls.getUnread(), method: 'GET' })
	}

	// [GET] - /user/rooms/:roomId - (AUTH)
	// ----------------------------------------------------------------------------
	public static getRoom(roomId: string, options?: AxiosRequestConfig) {
		return axiosInstance<RoomWithExtra>({ ...options, url: roomEndpointUrls.getRoom(roomId), method: 'GET' })
	}

	// [POST] - /user/rooms/:roomId/last-message-read - (AUTH)
	// ----------------------------------------------------------------------------
	public static setLastMessageRead(roomId: string, options?: AxiosRequestConfig) {
		return axiosInstance<Room>({ ...options, url: roomEndpointUrls.setLastMessageRead(roomId), method: 'POST' })
	}

	// [POST] - /user/rooms - (AUTH)
	// ----------------------------------------------------------------------------
	public static createRoom(data: RoomCreateDto, options?: AxiosRequestConfig) {
		return axiosInstance<Room>({
			...options,
			url: roomEndpointUrls.createRoom(),
			method: 'POST',
			data,
		})
	}

	// [GET] - /user/rooms/:roomId/message - (AUTH)
	// ----------------------------------------------------------------------------
	public static getMessages(roomId: string, options?: AxiosRequestConfig) {
		return axiosInstance<Array<RoomMessage>>({
			...options,
			url: roomEndpointUrls.getMessages(roomId),
			method: 'GET',
		})
	}

	// [POST] - /user/rooms/:roomId/message - (AUTH)
	// ----------------------------------------------------------------------------
	public static createMessage(roomId: string, data: RoomMessageCreateDto, options?: AxiosRequestConfig) {
		return axiosInstance<RoomMessage>({
			...options,
			url: roomEndpointUrls.createMessage(roomId),
			method: 'POST',
			data,
		})
	}

	// [POST] - /user/rooms/:roomId/message/upload - (AUTH)
	// ----------------------------------------------------------------------------
	public static uploadImage(roomId: string, data: RoomMessageUploadDto, options?: AxiosRequestConfig) {
		const formData = new FormData()
		formData.append('media', data.media)

		return axiosInstance<RoomMessage>({
			...options,
			url: roomEndpointUrls.uploadImage(roomId),
			method: 'POST',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}
}
