/*
| Developed by Dirupt
| Filename : theme.config.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Breakpoint } from '@mui/material/styles'

export const DESKTOP_BREAKPOINT: Breakpoint = 'md'
export const TABLET_BREAKPOINT: Breakpoint = 'sm'
