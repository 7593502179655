/*
| Developed by Dirupt
| Filename : DesktopNavItem.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Badge, BoxProps, Grid, styled } from '@mui/material';
import NextLink from 'next/link';
import { LinkProps } from 'next/dist/client/link';
import { useTheme } from '@mui/material/styles';
import { useActiveLink } from '@/hooks/useActiveLink';
import { MHomeProps } from '@/components/common/svgs/icons/MHome';
import { MRoomsProps } from '@/components/common/svgs/icons/MMessage';
import { MBellProps } from '@/components/common/svgs/icons/MBell';
import { MSearchProps } from '@/components/common/svgs/icons/MSearch';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopNavItemProps {
  type: 'link';
  icon: React.FC<MHomeProps | MRoomsProps | MBellProps | MSearchProps>;
  href: string;
  activeBaseLink: string;
  badge?: number;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const DesktopNavContainer = styled(props => <Grid item {...props} />, {
  shouldForwardProp: propName => propName !== 'active'
})<BoxProps & {
  active?: boolean;
}>(({
  theme,
  active
}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main
  },
  ...(active && {
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      left: 0,
      right: 0,
      width: '100%',
      height: 3,
      borderRadius: 6,
      backgroundColor: theme.palette.primary.main
    }
  })
}));
export const DesktopLink = styled(NextLink)<LinkProps>(({
  theme
}) => ({
  width: 32,
  height: 32,
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopNavItem: React.FC<DesktopNavItemProps> = ({
  icon,
  badge,
  href,
  activeBaseLink
}) => {
  const active = useActiveLink(activeBaseLink);
  const theme = useTheme();
  return <DesktopNavContainer active={active} data-sentry-element="DesktopNavContainer" data-sentry-component="DesktopNavItem" data-sentry-source-file="DesktopNavItem.tsx">
			<DesktopLink href={href} data-sentry-element="DesktopLink" data-sentry-source-file="DesktopNavItem.tsx">
				<Badge invisible={!badge} badgeContent={badge} color={'primary'} data-sentry-element="Badge" data-sentry-source-file="DesktopNavItem.tsx">
					{icon({
          fill: active ? theme.palette.primary.main : theme.palette.text.primary
        })}
				</Badge>
			</DesktopLink>
		</DesktopNavContainer>;
};