/*
| Developed by Senskle
| Filename: MUserProfileFilters.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MUserProfileFiltersProps extends SvgIconProps {
  // variant?: 'filled' | 'outlined'
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MUserProfileFilters: React.FC<MUserProfileFiltersProps> = ({
  ...props
}) => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MUserProfileFilters" data-sentry-source-file="MUserProfileFilters.tsx">
			<svg viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="MUserProfileFilters.tsx">
				<g clipPath="url(#clip0_1247_33299)" data-sentry-element="g" data-sentry-source-file="MUserProfileFilters.tsx">
					<path d="M4.14296 0C-10.374 7.434 18.792 16.74 8.02796 24C22.899 14.247 -7.73404 6.654 4.14296 0Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MUserProfileFilters.tsx" />
					<path d="M11.685 6.61198H15.3029C15.651 6.61198 15.936 6.32098 15.936 5.96398C15.936 5.60698 15.654 5.31598 15.3029 5.31598H11.685C11.337 3.76498 9.82795 2.79898 8.31295 3.15298C7.26295 3.39898 6.44095 4.23898 6.20095 5.31598H4.73395C4.38595 5.31598 4.10095 5.60698 4.10095 5.96398C4.10095 6.32098 4.38295 6.61198 4.73395 6.61198H6.20095C6.54895 8.16298 8.05795 9.12898 9.57295 8.77498C10.623 8.52898 11.445 7.68898 11.685 6.61198ZM7.39495 5.96398C7.39495 5.08798 8.09095 4.37998 8.94595 4.37998C9.80095 4.37998 10.494 5.09098 10.494 5.96698C10.494 6.84298 9.80095 7.55098 8.94595 7.55098C8.09095 7.55098 7.39795 6.83998 7.39795 5.96398H7.39495ZM22.5809 12.885H23.031C23.379 12.885 23.6639 12.594 23.6639 12.237C23.6639 11.88 23.382 11.589 23.031 11.589H22.5809C22.233 10.038 20.724 9.07198 19.209 9.42598C18.159 9.67198 17.337 10.512 17.097 11.589H11.175C10.827 11.589 10.542 11.88 10.542 12.237C10.542 12.594 10.824 12.885 11.175 12.885H17.097C17.445 14.436 18.954 15.402 20.469 15.048C21.519 14.802 22.341 13.962 22.5809 12.885ZM18.291 12.237C18.291 11.361 18.987 10.653 19.842 10.653C20.697 10.653 21.39 11.364 21.39 12.24C21.39 13.116 20.697 13.824 19.842 13.824C18.987 13.824 18.294 13.113 18.294 12.237H18.291ZM17.079 22.086C18.39 22.086 19.524 21.159 19.821 19.851H23.37C23.718 19.851 24.003 19.56 24.003 19.203C24.003 18.846 23.721 18.555 23.37 18.555H19.821C19.473 17.004 17.964 16.038 16.449 16.392C15.399 16.638 14.577 17.478 14.337 18.555H13.548C13.2 18.555 12.915 18.846 12.915 19.203C12.915 19.56 13.197 19.851 13.548 19.851H14.337C14.631 21.159 15.768 22.083 17.079 22.086ZM15.531 19.203C15.531 18.327 16.227 17.619 17.082 17.619C17.937 17.619 18.63 18.33 18.63 19.206C18.63 20.082 17.937 20.79 17.082 20.79C16.227 20.79 15.534 20.079 15.534 19.203H15.531Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MUserProfileFilters.tsx" />
					<path d="M23.244 1.452L23.232 1.443C23.025 1.251 22.761 1.146 22.482 1.146C22.17 1.146 21.87 1.281 21.66 1.518L17.679 5.979C17.643 6.021 17.616 6.069 17.598 6.12L17.13 7.557C17.076 7.722 17.103 7.908 17.202 8.049C17.301 8.193 17.463 8.277 17.634 8.277C17.709 8.277 17.781 8.262 17.847 8.232L19.203 7.626C19.251 7.605 19.296 7.572 19.332 7.53L23.313 3.069C23.727 2.604 23.694 1.881 23.241 1.458L23.244 1.452ZM18.027 7.287L18.303 6.444L18.327 6.417L18.849 6.903L18.825 6.93L18.03 7.287H18.027ZM22.749 2.532L19.365 6.324L18.843 5.838L22.227 2.046C22.293 1.971 22.383 1.932 22.482 1.932C22.569 1.932 22.65 1.965 22.716 2.025L22.728 2.034C22.869 2.166 22.878 2.388 22.749 2.532Z" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="path" data-sentry-source-file="MUserProfileFilters.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MUserProfileFilters.tsx">
					<clipPath id="clip0_1247_33299" data-sentry-element="clipPath" data-sentry-source-file="MUserProfileFilters.tsx">
						<rect width="24" height="24" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="rect" data-sentry-source-file="MUserProfileFilters.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};