/*
| Developed by Dirupt
| Filename : router-link.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { Link as MuiLink, LinkProps as MuiLinkProps, styled } from '@mui/material';
import { motion } from 'framer-motion';

/*
|--------------------------------------------------------------------------
| Router link wrapped with forward ref for MUI.
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line react/display-name
export const RouterLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>(({
  ...other
}, ref) => <NextLink ref={ref} onClick={event => event.stopPropagation()} {...other} />);
export const MotionRouterLink = motion(RouterLink, {
  forwardMotionProps: true
});

/*
|--------------------------------------------------------------------------
| MUI link with Router link component
|--------------------------------------------------------------------------
*/

const MuiLinkStyled = styled(MuiLink)<MuiLinkProps>(() => ({
  color: 'inherit',
  textDecoration: 'none'
}));

// eslint-disable-next-line react/display-name
export const Link = React.forwardRef<HTMLAnchorElement, MuiLinkProps>(({
  ...other
}, ref) => <MuiLinkStyled ref={ref} component={RouterLink} onClick={event => event.stopPropagation()} {...other} />);