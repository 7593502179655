import { Components, Theme } from '@mui/material'

export const radioOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiRadio: {
		styleOverrides: {
			root: {
				borderRadius: '50%',
				width: 16,
				height: 16,
				border: '2px solid',
				borderColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.text.primary,
				'&, &:hover': {
					backgroundColor:
						theme.palette.mode === 'light' ? theme.palette.secondaryExtra[25] : theme.palette.text.primary,
					svg: {
						display: 'none',
					},
				},
				'&.Mui-checked': {
					backgroundColor: theme.palette.primary.main,
				},
			},
		},
	},
})
