/*
| Developed by Dirupt
| Filename : MAvatar.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Avatar, AvatarProps, Skeleton, styled } from '@mui/material';
import { Link } from '@/components/common/MLink';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MAvatarProps extends AvatarProps {
  isLoading?: boolean;
  href?: string;
  alt?: string;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MAvatarStyled = styled(Avatar)<AvatarProps>(({
  theme
}) => ({
  padding: 2,
  border: `2px solid ${theme.palette.text.primary}`,
  background: theme.palette.background.default,
  '& img': {
    borderRadius: 9999
  }
}));

/*
|--------------------------------------------------------------------------
| Component with Skeleton if no src
|--------------------------------------------------------------------------
*/
export const MAvatar: React.FC<MAvatarProps> = ({
  isLoading,
  href,
  alt,
  ...rest
}) => {
  if (isLoading) {
    return <Skeleton variant={'circular'} width={40} height={40} />;
  }
  if (href) {
    return <Link href={href}>
				<MAvatarStyled {...rest} />
			</Link>;
  }
  return <MAvatarStyled {...rest} alt={alt} data-sentry-element="MAvatarStyled" data-sentry-component="MAvatar" data-sentry-source-file="MAvatar.tsx" />;
};