/*
| Developed by Dirupt
| Filename : DesktopNavButtonItem.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, ClickAwayListener, styled } from '@mui/material';
import { AnimatePresence, useCycle } from 'framer-motion';
import { MobileNavButtonItemProps } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNavButtonItem';
import { UrlsConfig } from '@/config/urls.config';
import { MClose, MCloseProps } from '@/components/common/svgs/icons/MClose';
import { MSearch, MSearchProps } from '@/components/common/svgs/icons/MSearch';
import { DesktopNavContainer } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItem';
import { DesktopNavButtonChildItem } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavButtonChildItem';
import { PartnerCategory } from '@/services/api/modules/partner/partner.contract';
import { SMenuUserIcon, SMenuUserIconProps } from '@/components/common/svgs/icons/SMenuUserIcon';
import { SMenuPartnerIcon, SMenuPartnerIconProps } from '@/components/common/svgs/icons/SMenuPartnerIcon';
import { SMenuEventIcon, SMenuEventIconProps } from '@/components/common/svgs/icons/SMenuEventIcon';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopNavButtonItemProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MenuUserIcon = styled(SMenuUserIcon)<SMenuUserIconProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));
const MenuPartnerIcon = styled(SMenuPartnerIcon)<SMenuPartnerIconProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));
const MenuEventIcon = styled(SMenuEventIcon)<SMenuEventIconProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));
const SearchIcon = styled(MSearch)<MSearchProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));
const CloseIcon = styled(MClose)<MCloseProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopNavButtonItem: React.FC<DesktopNavButtonItemProps> = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  // Nav items
  // ----------------------------------------------------------------------------
  const navItems = React.useMemo<Array<Omit<MobileNavButtonItemProps, 'toggleOpen' | 'custom'>>>(() => {
    return [{
      icon: <MenuUserIcon />,
      label: 'Utilisateurs',
      href: UrlsConfig.directory.users
    }, {
      icon: <MenuPartnerIcon />,
      label: 'Partenaires',
      href: UrlsConfig.directory.partners([PartnerCategory.ALL])
    }, {
      icon: <MenuEventIcon />,
      label: 'Évènements',
      href: UrlsConfig.directory.events
    }];
  }, []);

  // Render
  // ----------------------------------------------------------------------------
  return <ClickAwayListener onClickAway={() => {
    if (isOpen) {
      toggleOpen();
    }
  }} data-sentry-element="ClickAwayListener" data-sentry-component="DesktopNavButtonItem" data-sentry-source-file="DesktopNavButtonItem.tsx">
			<Box data-sentry-element="Box" data-sentry-source-file="DesktopNavButtonItem.tsx">
				<AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="DesktopNavButtonItem.tsx">
					{isOpen ? navItems.map((button, index) => <DesktopNavButtonChildItem key={index} custom={index} icon={button.icon} label={button.label} href={button.href} toggleOpen={toggleOpen} />) : null}
				</AnimatePresence>
				<DesktopNavContainer onClick={() => toggleOpen()} data-sentry-element="DesktopNavContainer" data-sentry-source-file="DesktopNavButtonItem.tsx">
					{isOpen ? <CloseIcon /> : <SearchIcon />}
				</DesktopNavContainer>
			</Box>
		</ClickAwayListener>;
};