/*
| Developed by Dirupt
| Filename : cookies.service.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { OptionsType } from 'cookies-next/lib/types'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { COOKIE_DOMAIN, COOKIE_SESSION_NAME } from '@/config/common.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type CookieName = 'senskle-locale' | 'senskle-color-mode' | 'senskle-informations-location'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class CookiesService {
	// Common
	// ----------------------------------------------------------------------------
	static getCookie(key: CookieName, options?: OptionsType) {
		return getCookie(key, options)
	}

	static deleteCookie(key: CookieName, options?: OptionsType) {
		return deleteCookie(key, options)
	}

	static setCookie<T>(key: CookieName, data: T, options?: OptionsType) {
		return setCookie(key, data, {
			domain: COOKIE_DOMAIN,
			sameSite: 'lax',
			...options,
		})
	}

	// Auth
	// ----------------------------------------------------------------------------
	static getAuthCookie(options?: OptionsType) {
		return getCookie(COOKIE_SESSION_NAME, options)
	}

	static deleteAuthCookie(options?: OptionsType) {
		return deleteCookie(COOKIE_SESSION_NAME, options)
	}
}
