/*
| Developed by Dirupt
| Filename : BadgeAdmin.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface BadgeAdminProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const BadgeAdmin: React.FC<BadgeAdminProps> = React.forwardRef((props, ref) => {
  return <SvgIcon {...props} inheritViewBox ref={ref}>
			<svg viewBox="0 0 64 64">
				<path d="M57.6188 7.61329L32.5215 0.0775188C32.1739 -0.0258396 31.798 -0.0258396 31.441 0.0775188L6.33426 7.61329C5.54498 7.84819 5 8.5811 5 9.40796V37.0235C5 40.6128 6.45641 44.2961 9.32226 47.9513C11.5116 50.7419 14.5466 53.5514 18.3238 56.2951C24.6851 60.8992 30.943 63.7181 31.2061 63.8403C31.4504 63.953 31.7134 64 31.9765 64C32.2396 64 32.5027 63.9436 32.747 63.8403C33.0101 63.7181 39.268 60.8992 45.6292 56.2951C49.4159 53.5514 52.4415 50.7513 54.6308 47.9513C57.4967 44.2961 58.9531 40.6128 58.9531 37.0235V9.40796C58.9531 8.5811 58.4081 7.84819 57.6188 7.61329Z" fill="#47484F" />
				<path d="M40.0291 46.7673C48.1662 39.4853 51.9623 32.8046 52.5731 27.4393C51.9811 24.3198 48.683 23.6902 44.5581 25.7856C46.1367 25.0997 47.377 25.1842 47.8844 26.6406C49.5851 31.0193 45.5447 38.4235 40.0291 46.7673Z" fill="white" />
				<path d="M52.6107 24.799C52.0093 18.3062 45.6293 18.0619 41.3728 20.5331C37.1445 22.9855 32.2491 27.7024 32.2491 27.7024C32.2491 27.7024 27.3536 22.9949 23.1253 20.5331C21.1991 19.4149 18.8313 18.8512 16.7641 19.18C18.0326 21.4445 20.8233 23.9721 23.5482 26.4433C27.0718 29.638 30.6705 32.9173 32.3712 36.5067C33.3766 35.3603 40.1889 27.7024 44.5205 25.8044C44.5299 25.8044 44.5393 25.795 44.5487 25.795C48.6831 23.6996 51.9811 24.3292 52.5637 27.4581C52.667 26.5279 52.6858 25.6446 52.6013 24.799H52.6107Z" fill="white" />
				<path d="M18.5775 11.9919C-4.59354 23.5869 41.9553 38.0946 24.779 49.4171C48.5045 34.214 -0.374639 22.3654 18.5775 11.9919Z" fill="white" />
				<path d="M36.8344 35.2006C40.1231 37.0141 44.8588 35.8583 45.4225 31.226C42.6225 31.6394 37.6988 31.132 36.8344 35.2006ZM38.7512 34.5523C39.174 32.5697 41.5701 32.8234 42.9325 32.6166C42.66 34.8717 40.358 35.4355 38.7512 34.5523Z" fill="white" />
			</svg>
		</SvgIcon>;
});
BadgeAdmin.displayName = 'BadgeAdmin';