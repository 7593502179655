/*
| Developed by Senskle
| Filename: SLogout.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SLogoutProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SLogout: React.FC<SLogoutProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SLogout" data-sentry-source-file="SLogout.tsx">
			<svg viewBox="0 0 24 24" fill={props?.fill ?? props?.stroke ?? 'white'} data-sentry-element="svg" data-sentry-source-file="SLogout.tsx">
				<path d="M8.32276 24H3.41793C2.50483 24 1.6469 23.6441 1.00138 22.9986C0.355862 22.3531 0 21.4952 0 20.5821V3.41793C0 2.50483 0.355862 1.6469 1.00138 1.00138C1.6469 0.355862 2.50483 0 3.41793 0H8.32276C8.85517 0 9.28828 0.433103 9.28828 0.965517C9.28828 1.49793 8.85517 1.93103 8.32276 1.93103H3.41793C3.02069 1.93103 2.64828 2.08552 2.3669 2.3669C2.08552 2.64828 1.93103 3.02069 1.93103 3.41793V20.5821C1.93103 20.9738 2.09103 21.3572 2.3669 21.6331C2.64828 21.9145 3.02069 22.069 3.41793 22.069H8.32276C8.85517 22.069 9.28828 22.5021 9.28828 23.0345C9.28828 23.5669 8.85517 24 8.32276 24ZM16.9048 19.0952C16.6566 19.0952 16.411 19.0014 16.2207 18.811C15.8428 18.4331 15.8428 17.8234 16.2207 17.4455L20.7034 12.9628H8.32276C7.79034 12.9628 7.35724 12.5297 7.35724 11.9972C7.35724 11.4648 7.79034 11.0317 8.32276 11.0317H20.7034L16.2207 6.54897C15.8428 6.17103 15.8428 5.56138 16.2207 5.18345C16.5986 4.80552 17.2083 4.80552 17.5862 5.18345L23.7159 11.3131C23.7159 11.3131 23.7186 11.3159 23.7214 11.3186C23.8097 11.4097 23.8786 11.5145 23.9228 11.6248C23.9697 11.7379 23.9972 11.8648 23.9972 11.9945C23.9972 12.1241 23.9697 12.251 23.9228 12.3641C23.8759 12.4745 23.8097 12.5766 23.7214 12.6676C23.7214 12.6676 23.7186 12.6703 23.7159 12.6731L17.5862 18.8028C17.3986 18.9903 17.1503 19.0869 16.9048 19.0869V19.0952Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SLogout.tsx" />
			</svg>
		</SvgIcon>;
};