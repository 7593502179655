/*
| Developed by Dirupt
| Filename : store.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import commonReducer from './common/commonSlice'

/*
|--------------------------------------------------------------------------
| Store configuration
|--------------------------------------------------------------------------
*/
export const store = configureStore({
	reducer: {
		common: commonReducer,
	},
})

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
