/*
| Developed by Dirupt
| Filename : ToastConfig.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Toaster } from 'sonner';
import { useResponsive } from '@/hooks/useResponsive';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ToastConfigProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ToastConfig: React.FC<ToastConfigProps> = () => {
  const theme = useTheme();
  const isDesktopOnly = useResponsive('up', 'md');
  return <Toaster visibleToasts={1} theme={theme.palette.mode} position={isDesktopOnly ? 'bottom-center' : 'top-center'} style={{
    zIndex: 9999
  }} expand richColors data-sentry-element="Toaster" data-sentry-component="ToastConfig" data-sentry-source-file="ToastConfig.tsx" />;
};