/*
| Developed by Dirupt
| Filename : EmptyLayout.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SFooter } from '@/components/common/SFooter/SFooter';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface EmptyLayoutProps {
  children: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EmptyLayout: React.FC<EmptyLayoutProps> = ({
  children
}) => {
  return <React.Fragment>
			{/*<Box minHeight={'100vh'} padding={DEFAULT_CONTENT_PADDING}>*/}
			{children}
			{/*</Box>*/}
			<SFooter data-sentry-element="SFooter" data-sentry-source-file="EmptyLayout.tsx" />
		</React.Fragment>;
};