/*
| Developed by Dirupt
| Filename : UserMenuItem.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, Chip, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemSecondaryAction, ListItemText, styled } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HTMLMotionProps } from 'framer-motion';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import useTranslation from 'next-translate/useTranslation';
import { DiruptUtils } from '@/utils';
import { usePremiumAbility } from '@/services/casl/hooks/usePremiumAbility';
import { Subjects } from '@/services/casl/casl.ability';
import { RouterLink } from '@/components/common/MLink';
import { useActiveLink } from '@/hooks/useActiveLink';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type NavItemLink = {
  type: 'link';
  href: string;
};
type NavItemButton = {
  type: 'button';
  onClick: () => void;
};
export type UserMenuItemProps = {
  icon: React.ReactNode;
  label: string;
  badgeText?: string;
  badgeColor?: 'primary' | 'secondary' | 'success' | 'error';
  disabled?: boolean;
  isPremiumFeature?: Extract<Subjects, 'premium'>;
} & (NavItemLink | NavItemButton);

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: propName => propName !== 'active'
})<ListItemButtonProps & {
  active?: boolean;
} & (HTMLMotionProps<'div'> | NextLinkProps)>(({
  theme,
  active,
  disabled
}) => ({
  ...(disabled ? {
    color: theme.palette.text.disabled,
    '& svg': {
      color: theme.palette.text.disabled
    }
  } : active ? {
    color: theme.palette.primary.main
  } : {
    color: theme.palette.text.primary
  })
}));
const ListItemIconStyled = styled(ListItemIcon)(({
  theme
}) => ({
  minWidth: theme.spacing(4.5),
  color: 'currentColor'
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UserMenuItem: React.FC<UserMenuItemProps> = item => {
  const {
    t
  } = useTranslation();
  const userIsPremium = usePremiumAbility();
  const active = useActiveLink(item.type === 'link' ? item.href : '');
  const isPremiumFeature = React.useMemo(() => {
    return item.isPremiumFeature === 'premium' && !userIsPremium;
  }, [item.isPremiumFeature, userIsPremium]);
  const canHaveAccess = React.useMemo(() => {
    return !item.isPremiumFeature || item.isPremiumFeature === 'premium' && userIsPremium;
  }, [item.isPremiumFeature, userIsPremium]);
  const isExternalLink = React.useMemo(() => {
    return item.type === 'link' && DiruptUtils.isExternalURL(item.href);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [item.type]);

  // Build props for the component ListItemButtonStyled depending on the type of the item
  // ----------------------------------------------------------------------------
  // @ts-ignore
  const props: ListItemButtonProps & HTMLMotionProps<'div'> & NextLinkProps = {
    disabled: item.disabled || !canHaveAccess,
    divider: true,
    ...(item.type === 'link' && {
      // component: MotionRouterLink,
      // variants: variantFade().inLeft,
      component: RouterLink,
      href: item.href,
      target: isExternalLink ? '_blank' : undefined
    }),
    ...(item.type === 'button' && {
      onClick: () => {
        if (item.type === 'button') {
          item.onClick();
        }
      }
      // component: m.div,
      // variants: variantFade().inLeft,
    })
  };

  // Render
  // ----------------------------------------------------------------------------
  return (
    // @ts-ignore
    <ListItemButtonStyled {...props} active={item.type === 'link' ? active : false} data-sentry-element="ListItemButtonStyled" data-sentry-component="UserMenuItem" data-sentry-source-file="UserMenuItem.tsx">
			<Box display={'flex'} flexDirection={'row'} alignItems={'center'} data-sentry-element="Box" data-sentry-source-file="UserMenuItem.tsx">
				<ListItemIconStyled data-sentry-element="ListItemIconStyled" data-sentry-source-file="UserMenuItem.tsx">{item.icon}</ListItemIconStyled>
				<ListItemText primary={item.label} data-sentry-element="ListItemText" data-sentry-source-file="UserMenuItem.tsx" />
				{item.badgeText || isPremiumFeature ? <ListItemSecondaryAction>
						<Chip label={t(isPremiumFeature ? 'common:common.premium' : item?.badgeText ?? 'common:common.premium')} variant={'outlined'} color={item?.badgeColor ?? isPremiumFeature ? 'primary' : 'success'} />
					</ListItemSecondaryAction> : null}
				{isExternalLink ? <ListItemSecondaryAction sx={{
          width: 16,
          height: 16,
          opacity: 0.5
        }}>
						<OpenInNewIcon sx={{
            fontSize: 16
          }} />
					</ListItemSecondaryAction> : null}
			</Box>
		</ListItemButtonStyled>
  );
};