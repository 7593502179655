/*
| Developed by Dirupt
| Filename : theme.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Roboto, Poppins } from 'next/font/google'
import { createTheme } from '@mui/material/styles'
import { getThemePalette } from '@/styles/theme.palette'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
declare module '@mui/material/styles' {
	export interface PaletteOptions {
		primaryExtra?: {
			75: string
			50: string
			25: string
			10: string
		}
		secondaryExtra?: {
			75: string
			50: string
			25: string
			10: string
		}
		neutral?: {
			black: string
			darkGrey: string
			grey: string
			lightGrey: string
			silver: string
			white: string
		}
		gender?: {
			male: string
			woman: string
			other: string
		}
		gradients?: {
			background: string
			pinkLight: string
			pink: string
			pinkOpacity: string
			lgbt: string
		}
	}

	export interface Palette {
		primaryExtra: {
			75: string
			50: string
			25: string
			10: string
		}
		secondaryExtra: {
			75: string
			50: string
			25: string
			10: string
		}
		neutral: {
			black: string
			darkGrey: string
			grey: string
			lightGrey: string
			silver: string
			white: string
		}
		gender: {
			male: string
			woman: string
			other: string
		}
		gradients: {
			background: string
			pinkLight: string
			pink: string
			pinkOpacity: string
			lgbt: string
		}
	}
}

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/
export const roboto = Roboto({
	weight: ['300', '400', '500', '700'],
	subsets: ['latin'],
	display: 'swap',
	preload: true,
})

export const poppins = Poppins({
	weight: ['300', '400', '500', '700'],
	subsets: ['latin'],
	display: 'swap',
	preload: true,
})

/*
|--------------------------------------------------------------------------
| Create theme
|--------------------------------------------------------------------------
*/
const theme = createTheme({
	palette: getThemePalette('dark'),
	typography: {
		fontFamily: poppins.style.fontFamily,
		button: {
			fontSize: 16,
			fontWeight: 590,
			lineHeight: '125%',
			textTransform: 'initial',
		},
		h1: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 28,
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: 'normal',
		},
		h2: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 24,
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: 'normal',
		},
		h3: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 24,
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: 'normal',
		},
		subtitle1: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 16,
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: 'normal',
		},
		subtitle2: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 20,
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: 'normal',
		},
		body1: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 14,
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
		},
		body2: {
			fontFamily: poppins.style.fontFamily,
			fontSize: 14,
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
		},
	},
})

export default theme
