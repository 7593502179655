/*
| Developed by Dirupt
| Filename : useCompleteAccountAbility.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { useAbility } from '@/services/casl/casl.context';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useCompleteAccountAbility = () => {
  const ability = useAbility();
  return React.useMemo(() => ability.can('quest', 'accountCompleted'), [ability]);
};