/*
| Developed by Dirupt
| Filename : i18n.js
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

module.exports = {
	locales: ['fr'],
	defaultLocale: 'fr',
	pages: {
		'*': ['common', 'errors'],
		'/': ['index'],
		'/feed': ['feed', 'notifications'],
		'/notifications': ['notifications'],
		'/payment/success': ['payment/success'],
		'/payment/cancel': ['payment/cancel'],
		'/payment/failure': ['payment/failure'],
		'/payment/pending': ['payment/pending'],
		'/rooms': ['rooms/list'],
		'/rooms/[id]': ['rooms/room'],
		'/rooms/[id]/request': ['rooms/request'],
		'/rooms/[id]/request/management': ['rooms/management'],
		'/directory/users': ['directory/users', 'account/filters'],
		'/directory/events': ['directory/events'],
		'/directory/partners': ['directory/partners', 'directory/partner-filters'],
		'/directory/partners/filters': ['directory/partner-filters'],
		'/admin/redeem-codes': ['admin/redeem-codes', 'account/index'],
		'/partner/[id]': ['partner/details'],
		'/partner/space': ['partner/space', 'account/index'],
		'/ambassador/space': ['ambassador/space', 'account/index'],
		'/ambassador/community': ['ambassador/community', 'account/index'],
		'/event/[id]': ['event/details'],
		'/event/[id]/success': ['payment/success'],
		'/event/management/list': ['event/list', 'account/index'],
		'/event/management/create': ['event/create', 'account/index'],
		'/event/management/[eventId]/edit': ['event/edit', 'account/index'],
		'/auth/login': ['auth/login', 'auth/common'],
		'/auth/create-account': ['auth/create-account', 'auth/common'],
		'/auth/verify': ['auth/verify', 'auth/common'],
		'/auth/beta-restricted': ['auth/beta-restricted', 'auth/common'],
		'/auth/forgot-password': ['auth/forgot-password', 'auth/common'],
		'/auth/reset-password': ['auth/reset-password', 'auth/common'],
		'/post/create': ['post/create'],
		'/post/[id]': ['post/details'],
		'/profile/[id]': ['profile/details', 'account/index'],
		'/account': ['account/index'],
		'/account/informations': ['account/informations', 'account/index'],
		'/account/password': ['account/password', 'account/index'],
		'/account/profile': ['account/profile', 'account/index'],
		'/account/user': ['account/user', 'account/index'],
		'/account/crushes': ['account/crushes', 'account/index'],
		'/account/media-folder-requests': ['account/media-folder-requests', 'account/index'],
		'/account/blacklist': ['account/blacklist', 'account/index'],
		'/account/filters': ['account/filters', 'account/index'],
		'/account/associated-profiles': ['account/associated-profiles'],
		'/account/become-partner': ['account/become-partner', 'account/index'],
		'/account/kyc': ['account/kyc', 'account/index'],
		'/account/questions': ['account/questions', 'account/index'],
		'/account/settings': ['account/settings', 'account/index'],
		'/account/sponsorship': ['account/sponsorship', 'account/index'],
		'/account/subscription': ['account/subscription', 'account/index'],
		'/account/onboarding': ['account/onboarding'],
	},
	loadLocaleFrom: async (lang, ns) => {
		// You can use a dynamic import, fetch, whatever. You should
		// return a Promise with the JSON file.
		// yaml already being transformed by webpack plugin
		// const m = require(`./locales/${lang}/${ns}.yml`)
		const m = require(`./locales/${lang}/${ns}.yml`)

		// eslint-disable-next-line no-undef
		return Promise.resolve(m.default)
	},
}
