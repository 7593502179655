
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /*
| Developed by Dirupt
| Filename : _app.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/
import 'react-multi-carousel/lib/styles.css';
import * as React from 'react';
import { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { NextComponentType, NextPageContext } from 'next';
import { SWRConfig } from 'swr';
import { Provider } from 'react-redux';
import Head from 'next/head';
import createEmotionCache from '@/config/createEmotionCache';
import { SenskleExtendNextPageProps } from '@/components/ssr/SensklePage';
import { AppLayout } from '@/components/layouts/app/AppLayout';
import { store } from '@/stores/store';
import { SenskleAppStructure } from '@/components/structure/SenskleAppStructure';
/*
|--------------------------------------------------------------------------
| Client-side cache, shared for the whole session of the user-information in the browser.
|--------------------------------------------------------------------------
*/
const clientSideEmotionCache = createEmotionCache();
/*
|--------------------------------------------------------------------------
| SWR Global Default Config
|--------------------------------------------------------------------------
*/
const SWRGlobalDefaultConfig = {
    refreshInterval: 60000, // 1 minute
};
/*
|--------------------------------------------------------------------------
| Application interface modifier
|--------------------------------------------------------------------------
*/
export interface SenskleAppProps extends Omit<AppProps, 'Component'> {
    Component: NextComponentType<NextPageContext, any, any> & SenskleExtendNextPageProps;
    emotionCache?: EmotionCache;
}
/*
|--------------------------------------------------------------------------
| Application layout
|--------------------------------------------------------------------------
*/
function SenskleApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache, }: SenskleAppProps) {
    return (<React.Fragment>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
			</Head>
			<CacheProvider value={emotionCache}>
				<Provider store={store}>
					<SWRConfig value={Component?.swrConfig ?? SWRGlobalDefaultConfig}>
						<SenskleAppStructure router={router} layout={Component?.layout ?? 'default'}>
							<AppLayout layout={Component?.layout ?? 'default'}>
								<Component {...pageProps}/>
							</AppLayout>
						</SenskleAppStructure>
					</SWRConfig>
				</Provider>
			</CacheProvider>
		</React.Fragment>);
}

    export default __appWithI18n(SenskleApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  