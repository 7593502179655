/*
| Developed by Dirupt
| Filename : fab.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const fabOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiFab: {
		styleOverrides: {
			root: {
				boxShadow: 'none',
				zIndex: 1,

				'&:hover': {
					backgroundColor: theme.palette.background.paper,
					borderColor: theme.palette.primary.main,
				},
			},
		},
	},
})
