/*
| Developed by Dirupt
| Filename : media.services.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { API_HOST } from '@/config/common.config'
import { User } from '@/services/api/modules/user/user.contract'
import { UserInformation } from '@/services/api/modules/user-information/user-information.contract'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type MediaAlterationOptions = {
	format?: 'webp' | 'png' | 'jpeg'
	f?: 'webp' | 'png' | 'jpeg'
	width?: number
	w?: number
	quality?: number
	q?: number
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const defaultMediaAlterationOptions: MediaAlterationOptions = {
	format: 'webp',
	quality: 80,
}

/*
|--------------------------------------------------------------------------
| Helper Function
|--------------------------------------------------------------------------
*/
function createQueryString(options?: MediaAlterationOptions): string {
	const params = new URLSearchParams()
	const mergedOptions = { ...defaultMediaAlterationOptions, ...options }

	// Mapping options to their respective query params
	if (mergedOptions.format) params.append('f', mergedOptions.format.toString())
	if (mergedOptions.f) params.append('f', mergedOptions.f.toString())
	if (mergedOptions.width) params.append('w', mergedOptions.width.toString())
	if (mergedOptions.w) params.append('w', mergedOptions.w.toString())
	if (mergedOptions.quality) params.append('q', mergedOptions.quality.toString())
	if (mergedOptions.q) params.append('q', mergedOptions.q.toString())

	return params.toString()
}

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class MediaServices {
	/**
	 * Get media from avatar url.
	 * @param avatarUrl
	 * @param options
	 */
	public static getMediaFromUrl(avatarUrl: string, options?: MediaAlterationOptions) {
		const queryString = createQueryString(options)
		return avatarUrl ? `${API_HOST}${avatarUrl}?${queryString}` : '/assets/images/logo/senskle-default.png'
	}

	/**
	 * Get avatar from user.
	 * @param user
	 * @param options
	 */
	public static getAvatarFromUser(user: User, options?: MediaAlterationOptions) {
		const queryString = createQueryString(options)

		return user?.userInformation?.avatarUrl
			? `${API_HOST}${user.userInformation.avatarUrl}?${queryString}`
			: '/assets/images/logo/senskle-default.png'
	}

	/**
	 * Get avatar from user information.
	 * @param userInformation
	 * @param options
	 */
	public static getAvatarFromUserInformation(userInformation: UserInformation, options?: MediaAlterationOptions) {
		const queryString = createQueryString(options)
		return userInformation?.avatarUrl
			? `${API_HOST}${userInformation.avatarUrl}?${queryString}`
			: '/assets/images/logo/senskle-default.png'
	}

	/**
	 * Download image from url.
	 * @param url
	 */
	public static async downloadImage(url?: string) {
		if (!url) return undefined

		const response = await fetch(url, {
			credentials: 'include',
		})
		if (!response.ok) {
			throw new Error('Image download failed')
		}
		return await response.blob()
	}

	/**
	 * Convert blob to file.
	 * @param theBlob
	 * @param fileName
	 */
	public static blobToFile = (theBlob: Blob, fileName: string): File => {
		return new File([theBlob], fileName, { type: theBlob.type })
	}
}
