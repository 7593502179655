/*
| Developed by Dirupt
| Filename : axios-instance.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

// eslint-disable-next-line import/no-named-as-default
import Axios from 'axios'
import { commonErrorResponseInterceptor, commonSuccessResponseInterceptor } from '@/services/api/axios-interceptors'
import { API_HOST } from '@/config/common.config'

/*
|--------------------------------------------------------------------------
| Create axios instance
|--------------------------------------------------------------------------
*/
export const axiosInstance = Axios.create({
	baseURL: API_HOST,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
})

/*
|--------------------------------------------------------------------------
| Default response interceptor
|--------------------------------------------------------------------------
*/
axiosInstance.interceptors.response.use(commonSuccessResponseInterceptor, commonErrorResponseInterceptor)
