/*
| Developed by Dirupt
| Filename : menu.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'
import { DESKTOP_BREAKPOINT } from '@/styles/theme.config'

export const menuOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiMenu: {
		styleOverrides: {
			paper: {
				width: '100%',
				backgroundImage: 'none',
				border: `1px solid ${theme.palette.divider}`,

				[theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
					width: 'fit-content',
					minWidth: 300,
				},
			},
		},
	},
})
