/*
| Developed by Senskle
| Filename: MHome.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MHomeProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MHome: React.FC<MHomeProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MHome" data-sentry-source-file="MHome.tsx">
			<svg viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="MHome.tsx">
				<g clipPath="url(#clip0_116_14912)" data-sentry-element="g" data-sentry-source-file="MHome.tsx">
					<path d="M9.07257 19.9376C8.58664 19.9376 8.19257 19.5454 8.19257 19.0654C8.19257 18.5854 8.58664 18.1932 9.07257 18.1932H14.9244C15.4103 18.1932 15.8074 18.5854 15.8074 19.0654C15.8074 19.5454 15.4133 19.9376 14.9244 19.9376H9.07257Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MHome.tsx" />
					<path fillRule="evenodd" clipRule="evenodd" d="M4.39407 24C1.97333 24 0 22.0478 0 19.6449V10.3083C0 8.96781 0.607407 7.72098 1.67111 6.88976L9.27704 0.936585C10.0474 0.333659 11.0163 0 12 0C12.9837 0 13.9496 0.333659 14.723 0.936585L22.3319 6.89268C23.3926 7.7239 24.003 8.96781 24.003 10.3112V19.6478C24.003 22.0478 22.0326 24.0029 19.6119 24.0029H4.39407V24ZM12 1.74439C11.4104 1.74439 10.8326 1.94341 10.3704 2.30634L2.76444 8.26244C2.12741 8.76 1.76296 9.50634 1.76296 10.3112V19.6478C1.76296 21.0849 2.94222 22.2556 4.39407 22.2556H19.6089C21.0578 22.2556 22.24 21.0849 22.24 19.6478V10.3112C22.24 9.50634 21.8756 8.76 21.2385 8.26244L13.6296 2.30634C13.1674 1.94341 12.5896 1.74439 12 1.74439Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MHome.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MHome.tsx">
					<clipPath id="clip0_116_14912" data-sentry-element="clipPath" data-sentry-source-file="MHome.tsx">
						<rect width="24" height="24" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="MHome.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};