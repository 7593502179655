/*
| Developed by Dirupt
| Filename : SMenuUserIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SMenuUserIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SMenuUserIcon: React.FC<SMenuUserIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SMenuUserIcon" data-sentry-source-file="SMenuUserIcon.tsx">
			<svg viewBox="0 0 87 80" data-sentry-element="svg" data-sentry-source-file="SMenuUserIcon.tsx">
				<g clipPath="url(#clip0_629_11)" data-sentry-element="g" data-sentry-source-file="SMenuUserIcon.tsx">
					<path d="M71.9051 22.8615C71.9051 13.2218 64.09 5.4128 54.4564 5.4128C49.9863 5.4128 45.9054 7.09746 42.822 9.86468C48.0766 17.3453 54.347 28.3412 56.2506 40.225C65.0449 39.331 71.9112 31.899 71.9112 22.8676L71.9051 22.8615Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
					<path d="M84.8229 67.4107C83.345 62.6486 80.7603 58.3001 77.2815 54.724C71.6132 48.9098 64.2725 45.7351 56.7676 45.1939C57.2358 54.7788 54.4808 64.6921 45.796 73.6202C42.0557 77.6646 38.3154 80 38.3154 80H83.2538C84.9385 80 86.3069 78.6316 86.3069 76.9469C86.319 73.7114 85.8203 70.4881 84.8229 67.4107Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
					<path d="M51.9019 45.1939C51.8228 43.5214 51.6464 41.861 51.3788 40.2189C49.4752 28.3351 43.2049 17.3392 37.9502 9.8586C33.6686 3.75855 30.0621 0 30.0621 0V0.0851452C30.8649 10.5823 28.7424 21.0917 23.9438 30.4333C22.7214 18.6346 19.048 14.2861 19.048 14.2861C19.048 14.2861 19.9907 23.4028 16.6031 28.5662C5.58895 45.3277 12.9358 54.0307 12.9358 54.0307C3.75833 54.0307 4.3726 37.8835 4.3726 37.8835C4.3726 37.8835 -10.315 63.348 14.1643 77.622C15.9219 78.6498 17.9593 79.4283 20.1184 79.9331C20.088 79.9209 20.0576 79.9027 20.0272 79.8905C19.6379 79.7871 19.2791 79.6108 18.9689 79.3736C18.3851 79.0512 17.8377 78.6863 17.3451 78.2606C15.2894 76.4726 14.2677 73.6932 14.0366 70.9503C13.8116 68.2135 14.2859 65.4584 14.7603 62.7581C14.736 66.0119 16.074 69.1683 18.2148 71.5767C20.0636 73.6506 22.5146 75.1711 25.2332 75.6941C22.843 72.4403 20.6414 68.7791 20.167 64.8016C20.1062 64.3029 20.0697 63.7981 20.0697 63.2933C20.0576 59.778 21.505 56.433 23.1714 53.3496C25.6407 48.7821 28.6268 44.5309 31.0717 39.9574C32.057 38.1207 33.8024 35.0555 34.8303 32.1545C35.4567 30.3786 35.8216 28.6635 35.5662 27.3316C35.9554 29.3873 35.9736 31.4429 35.7608 33.4864C35.1952 38.8505 32.9571 44.1295 31.169 49.0558C30.8649 49.8951 30.573 50.7587 30.2932 51.6284C28.4322 57.5399 27.5017 63.9988 30.4027 69.3264C33.395 74.8061 37.336 63.8285 37.8347 61.7789C38.5584 58.8475 39.0328 55.7944 39.2335 52.7839C39.2335 52.7839 43.0224 68.177 40.9242 73.6141C49.609 64.686 52.3641 54.7727 51.8958 45.1878L51.9019 45.1939Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
					<path d="M20.0273 79.8905C19.6624 79.7385 19.3097 79.5621 18.9691 79.3736C19.2793 79.6108 19.6381 79.7871 20.0273 79.8905Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="SMenuUserIcon.tsx">
					<clipPath id="clip0_629_11" data-sentry-element="clipPath" data-sentry-source-file="SMenuUserIcon.tsx">
						<rect width="86.3008" height="80" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="SMenuUserIcon.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};