/*
| Developed by Dirupt
| Filename : PremiumPage.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { m } from 'framer-motion';
import { MButton } from '@/components/common/MButton';
import { MLock } from '@/components/common/svgs/icons/MLock';
import { MotionContainer, variantFade } from '@/components/animate';
import { Link } from '@/components/common/MLink';
import { UrlsConfig } from '@/config/urls.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PremiumPageProps {
  title?: string;
  description?: string;
  customButton?: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PremiumPage: React.FC<PremiumPageProps> = ({
  title,
  description,
  customButton
}) => {
  const {
    t
  } = useTranslation();
  return <Box display={'flex'} flexDirection={'column'} gap={2} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'} component={MotionContainer} data-sentry-element="Box" data-sentry-component="PremiumPage" data-sentry-source-file="PremiumPage.tsx">
			<Card component={m.div} variants={variantFade().in} data-sentry-element="Card" data-sentry-source-file="PremiumPage.tsx">
				<Box display={'flex'} flexDirection={'column'} gap={2} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'} padding={4} data-sentry-element="Box" data-sentry-source-file="PremiumPage.tsx">
					<MLock sx={{
          fontSize: 40
        }} data-sentry-element="MLock" data-sentry-source-file="PremiumPage.tsx" />
					<Typography variant={'h3'} data-sentry-element="Typography" data-sentry-source-file="PremiumPage.tsx">{title || t('common:premiumPage.title')}</Typography>
					<Typography data-sentry-element="Typography" data-sentry-source-file="PremiumPage.tsx">{description || t('common:premiumPage.description')}</Typography>
					<Box display={'flex'} flexDirection={'row'} gap={2} justifyContent={'center'} alignItems={'center'} data-sentry-element="Box" data-sentry-source-file="PremiumPage.tsx">
						{customButton || <MButton variant={'contained'} component={Link} href={UrlsConfig.account.subscription}>
								{t('common:premiumPage.buttons.seeSubscription')}
							</MButton>}
					</Box>
				</Box>
			</Card>
		</Box>;
};