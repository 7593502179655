/*
| Developed by Senskle
| Filename: MMessage.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MRoomsProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MRooms: React.FC<MRoomsProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MRooms" data-sentry-source-file="MRooms.tsx">
			<svg viewBox="0 0 24 24" fill={props.fill ?? props.stroke ?? 'white'} data-sentry-element="svg" data-sentry-source-file="MRooms.tsx">
				<path d="M12.639 1.8H13.284C15.606 1.941 17.79 2.922 19.434 4.566C21.081 6.213 22.062 8.394 22.2 10.716V11.337C22.203 12.807 21.855 14.277 21.189 15.594C20.397 17.178 19.182 18.51 17.676 19.44C16.17 20.373 14.436 20.865 12.663 20.865H12.639C11.178 20.865 9.717 20.517 8.412 19.857C8.157 19.728 7.881 19.665 7.599 19.665C7.407 19.665 7.215 19.695 7.029 19.758L2.844 21.153L4.239 16.968C4.392 16.512 4.353 16.017 4.137 15.588C3.474 14.277 3.126 12.807 3.129 11.334C3.129 9.564 3.624 7.83 4.554 6.324C5.484 4.818 6.816 3.603 8.406 2.808C9.711 2.148 11.172 1.8 12.633 1.8M12.633 0C10.884 0 9.159 0.411 7.596 1.2C5.715 2.142 4.131 3.588 3.024 5.376C1.917 7.167 1.332 9.228 1.329 11.334C1.323 13.095 1.737 14.829 2.529 16.401L0 24L7.599 21.468C9.162 22.257 10.887 22.668 12.636 22.668C12.645 22.668 12.657 22.668 12.666 22.668C14.769 22.668 16.833 22.08 18.624 20.973C20.415 19.866 21.861 18.282 22.8 16.401C23.592 14.829 24.006 13.095 24 11.334V10.668C23.847 7.89 22.674 5.265 20.706 3.297C18.738 1.326 16.113 0.153 13.332 0H12.666C12.666 0 12.648 0 12.636 0H12.633Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MRooms.tsx" />
			</svg>
		</SvgIcon>;
};