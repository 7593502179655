/*
| Developed by Dirupt
| Filename : MotionViewport.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { m, MotionProps } from 'framer-motion';
import Box, { BoxProps } from '@mui/material/Box';
import { variantContainer } from './variants';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ExtendsProps = BoxProps & MotionProps;
interface MotionViewportProps extends ExtendsProps {
  children: React.ReactNode;
  disableAnimatedMobile?: boolean;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
export const MotionViewport: React.FC<MotionViewportProps> = ({
  children,
  disableAnimatedMobile = true,
  ...other
}) => {
  // const smDown = useResponsive('down', 'sm')
  //
  // if (smDown && disableAnimatedMobile) {
  // 	return <Box {...other}>{children}</Box>
  // }

  return <Box component={m.div} initial="initial" whileInView="animate" viewport={{
    once: true,
    amount: 0.3
  }} variants={variantContainer()} {...other} data-sentry-element="Box" data-sentry-component="MotionViewport" data-sentry-source-file="MotionViewport.tsx">
			{children}
		</Box>;
};