/*
| Developed by Dirupt
| Filename : AppLayout.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { frFR, enUS, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useTranslation from 'next-translate/useTranslation';
import { GlobalStyles } from '@mui/material';
import { LayoutTypes, SenskleExtendNextPageProps } from '@/components/ssr/SensklePage';
import { axiosInstance } from '@/services/api/axios-instance';
import { commonErrorResponseInterceptor, commonSuccessResponseInterceptor } from '@/services/api/axios-interceptors';
import { EmptyLayout } from '@/components/layouts/app/EmptyLayout';
import { DefaultLayout } from '@/components/layouts/app/DefaultLayout';
import { AuthLayout } from '@/components/layouts/app/AuthLayout';
import { SettingsLayout } from '@/components/layouts/app/SettingsLayout';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface AppLayoutProps {
  children: React.ReactNode;
  layout?: SenskleExtendNextPageProps['layout'];
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const LAYOUT_MAPPING: Record<LayoutTypes, React.FC<{
  children: React.ReactNode;
}>> = {
  default: DefaultLayout,
  empty: EmptyLayout,
  auth: AuthLayout,
  settings: SettingsLayout
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  layout
}) => {
  const {
    lang
  } = useTranslation();
  React.useEffect(() => {
    console.log('mounted');
    return () => {
      console.log('unmounted');
    };
  }, []);

  // Axios interceptors
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    /**
     * Interceptor for Senskle's axios instance
     */
    axiosInstance.interceptors.response.use(commonSuccessResponseInterceptor, commonErrorResponseInterceptor);
    axiosInstance.interceptors.request.use(config => {
      return config;
    }, error => {
      // Do something with request error
      return Promise.reject(error);
    });
  }, []);
  const localText = React.useMemo(() => {
    if (lang === 'fr') {
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    }
    return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }, [lang]);

  // Get current layout
  // ----------------------------------------------------------------------------
  const CurrentLayout = LAYOUT_MAPPING?.[layout ?? 'default'] ?? ((children: React.ReactNode) => <React.Fragment>{children}</React.Fragment>);

  // Render
  //--------------------------------------------------------------------------
  return <React.Fragment>
			<GlobalStyles styles={theme => ({
      html: {
        backgroundColor: `${theme.palette.background.default} !important`
      },
      body: {
        overflowX: 'hidden'
      },
      /* width */
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: theme.palette.background.default
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.divider,
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.divider
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent'
      }
    })} data-sentry-element="GlobalStyles" data-sentry-source-file="AppLayout.tsx" />
			<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={lang} localeText={localText} data-sentry-element="LocalizationProvider" data-sentry-source-file="AppLayout.tsx">
				<CurrentLayout data-sentry-element="CurrentLayout" data-sentry-source-file="AppLayout.tsx">{children}</CurrentLayout>
			</LocalizationProvider>
		</React.Fragment>;
};