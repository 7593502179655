/*
| Developed by Dirupt
| Filename : form-control.overrides.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Components, Theme } from '@mui/material'

export const formControlOverrides = (theme: Theme): Components<Omit<Theme, 'components'>> => ({
	MuiFormControl: {
		styleOverrides: {
			root: {
				'& .MuiFormLabel-root': {
					width: '100%',
					textAlign: 'left',
					fontStyle: 'italic',
					color: theme.palette.text.primary,
				},
			},
		},
		defaultProps: {
			fullWidth: true,
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				color: theme.palette.text.primary,
			},
		},
	},
})
